const ThingPresenter = {
  methods: {
    thingNumber(thing, groupSeparator) {
      let str = '';

      if (thing.deployment.group.number != null && thing.deployment.group.number > -1) {
        str = `${thing.deployment.group.number}${groupSeparator}${thing.deployment.number}`;
      } else {
        str += thing.deployment.number; // stringify number
      }

      if (thing.deployment.sub_number !== 0) {
        str = `${str}.${thing.deployment.sub_number}`;
      }
      return str;
    },
    isSubThing(thing) {
      return (thing.deployment && thing.deployment.sub_number !== 0);
    },
    thingTypeCategory(thing) {
      let categoryLabel = 'Komponente';

      if (this.isSubThing(thing)) {
        categoryLabel = 'Sub-Komponente';
      } else if (this.isCentralUnit(thing)) {
        categoryLabel = 'Zentrale';
      }
      return categoryLabel;
    },
    groupNumberAndName(thing) {
      const group = thing.deployment.group;
      const groupName = group.name == null ? '' : group.name;

      if (group == null) {
        return '';
      }
      if (group != null && group.number > -1) {
        return `${group.number} ${groupName}`;
      }
      return groupName;
    },
    thingMake(thing) {
      return [thing.thing_type.manufacturer, thing.thing_type.model].join(' ');
    },
    humanTagSerial(thing) {
      if (thing.active_tag_serial == null) { return null; }

      return thing.active_tag_serial.replace('https://www.foxtag.io/id/', '');
    },
    hasFoxtagId(thing) {
      if (thing.active_tag_serial == null) { return false; }

      return (thing.active_tag_serial.indexOf('https://www.foxtag.io/id/') > -1);
    },
    hasCheckInterval(thing) {
      return thing.thing_type.check_interval_id || !thing.inherit_check_interval;
    },
    hasReplacementInterval(thing) {
      return thing.thing_type.replacement_interval_id;
    },
    isCentralUnit(thing) {
      return thing.thing_type.category === 'central_unit';
    },
    isDeployed(thing) {
      return thing.status === 'deployed';
    },
  },
};
export default ThingPresenter;
