<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <h2 class="fx">
      Firmen-Account wechseln
    </h2>

    <i v-if="!loaded" class="fx-spinner" />

    <div v-for="user in users" :key="user.id" @click="switchUser(user.id)">
      <div
        v-if="notPortal(user)"
        style="cursor: pointer;"
        class="fx-button-chooser"
        @click="switchUser(user.id)"
      >
        <div class="float-right" style="color: #666; font-size: .8rem; text-align: right;">
          <div v-if="isCurrentUser(user.id)">
            <span class="fx-status success">zur Zeit aktiv</span>
          </div>
        </div>
        <strong style="color: #256FC5;">{{ user.organization_name }}</strong> <sup><span
          :class="`license-type-label license-type-${user.organization_license_type}`"
        >{{ user.organization_license_type
        }}</span></sup>
        <div style="font-size: .8rem;">
          {{ user.organization_branch }}
        </div>

        <div style="clear: both;" />
        <div class="secondary text-right" style="font-size: .8rem;">
          {{ user.human_roles.join(", ") }}
        </div>
      </div>
    </div>

    <a href="/portal/locations">
      <div
        v-if="anyPortalUsers"
        style="cursor: pointer; background: linear-gradient(135deg, #2c556e 17%, #4c7596 100%);"
        class="fx-button-chooser"
      >
        <strong style="color: white;">Auftraggeberportal</strong>
      </div>
    </a>
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash';
import UserModel from 'apps/application/user_model';

export default {
  name: 'SwitchUserModal',
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      users: [],
      loaded: false,
      anyPortalUsers: false,
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    notPortal(user) {
      const isPortal = UserModel.isPortal(user);

      if (isPortal) {
        this.anyPortalUsers = true;
      }

      return !isPortal;
    },
    switchUser(userId) {
      window.location.href = `/accounts/switch_user?user_id=${userId}`;
    },
    isCurrentUser(userId) {
      return (this.currentUser.id === userId);
    },
    loadUsers() {
      axios.get('/accounts/users').then((response) => {
        this.users = response.data.users;
        this.loaded = true;
      }).catch(() => {
        Flash.error('Fehler beim Laden der Nutzerliste');
      });
    },
  },
};
</script>
