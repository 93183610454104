export function $tabClasses(tab) {
  if (tab === this.activeTab) {
    return 'fx-tab fx-tab__active';
  }
  return 'fx-tab';
}
export function $isTabInitialized(tab) {
  return this.initializedTabs.has(tab);
}

export function $isTabActive(tab) {
  return (this.activeTab === tab);
}

export function $setActiveTab(tab, addToURL = true) {
  this.activeTab = tab;
  this.initializedTabs.add(tab);
  if (addToURL) {
    window.location.hash = `#${tab}`;
  }
}

export function $setActiveTabFromURL(defaultTab) {
  const hash = window.location.hash.substr(1);
  if (hash) {
    this.$setActiveTab(hash);
  } else {
    this.$setActiveTab(defaultTab);
  }
}

export function $initTabs(tab) {
  this.initializedTabs = new Set();
  this.$setActiveTab(tab);
}
