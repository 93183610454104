const FoxtagPaths = {
  methods: {
    customersPath() {
      return '/customers/';
    },
    articlesPath() {
      return '/articles/';
    },
    locationsPath() {
      return '/locations/';
    },
    jobsPath() {
      return '/jobs/';
    },
    locationPath(locationId) {
      return `/locations/${locationId}`;
    },
    locationImportPath() {
      return '/imports/locations/new';
    },
    installationPath(installatioId, anchor) {
      const appliedAnchor = (anchor != null) ? `#${anchor}` : '';

      return `/installations/${installatioId}${appliedAnchor}`;
    },
    installationJobsPath(installationId) {
      return `/installations/${installationId}/jobs`;
    },
    installationAttachmentDownloadPath(installationId, attachmentId) {
      return `/installations/${installationId}/attachments/${attachmentId}/download`;
    },
    installationTypePath(installationTypeId) {
      return `/installation_types/${installationTypeId}`;
    },
    installationTypeExportPath(installationTypeId) {
      return `/installation_types/${installationTypeId}/export.json`;
    },
    incidentPath(incidentId) {
      return `/incidents/${incidentId}`;
    },
    jobForInstallationPath(installationId) {
      return `/jobs/new?installation_id=${installationId}`;
    },
    jobPath(jobId) {
      return `/jobs/${jobId}`;
    },
    customerPath(customerId) {
      return `/customers/${customerId}`;
    },
    jobIcalPath(jobId) {
      return `/jobs/${jobId}.ics`;
    },
    jobsImportPath() {
      return '/imports/jobs/new';
    },
    jobArticlesImportPath(jobId) {
      return `/imports/job_articles/new?job=${jobId}`;
    },
    jobArticlesExportPath(jobId, fileType) {
      return `/jobs/${jobId}/export_articles?file_type=${fileType}`;
    },
    portalNewIncidentPath() {
      return `/portal/incidents/new/?installation_id=${this.sharedInstallation.installation.id}`;
    },
    portalSharedInstallationPath(sharedInstallationId) {
      return `/portal/shared_installations/${sharedInstallationId}`;
    },
    portalSharedInstallationJobPath(sharedInstallationId, jobId) {
      return `/portal/shared_installations/${sharedInstallationId}?job=${jobId}`;
    },
    thingPath(thingId) {
      return `/things/${thingId}`;
    },
  },
};
export default FoxtagPaths;
