/* eslint-disable no-param-reassign */
import { createApp } from 'vue/dist/vue.esm-bundler';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';

import { createPinia } from 'pinia';

import i18n from 'initializers/vue_i18n';

// plugins, components import
import HelpArticle from 'components/help_article';
import Topbar from 'apps/application/topbar/topbar.vue';
import NoDistractionTopbar from 'apps/application/topbar/no_distraction_topbar.vue';
import Icon from 'components/icon.vue';

import VueTippy from 'vue-tippy';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'; // tippy styling

import * as GlobalTabFunctions from 'initializers/tabs';

const apps = [];

const initApps = ($context) => {
  if (!$context.querySelectorAll) { $context = document; }

  const vApps = [];

  // for (const app of apps) {
  apps.forEach((app) => {
    if (!app.el) {
      // eslint-disable-next-line no-console
      console.error(`VueAppFactory expects an 'el' property: ${JSON.stringify(app)}`);
      return;
    }

    const $el = $context.querySelectorAll(app.el)[0];
    if ($el) {
      app = createApp({
        data() {
          return {};
        },
        ...app,
      });
      // plugins, components, GlobalProperties(old prototype)

      const vfm = createVfm();
      const pinia = createPinia();

      app.use(i18n)
        .use(pinia)
        .use(vfm)
        .use(VueTippy);

      app.component('HelpArticle', HelpArticle);
      // eslint-disable-next-line vue/multi-word-component-names
      app.component('Topbar', Topbar);
      app.component('NoDistractionTopbar', NoDistractionTopbar);
      // eslint-disable-next-line vue/multi-word-component-names
      app.component('Icon', Icon);

      app.config.globalProperties = {
        ...(app.config.globalProperties || {}),
        ...GlobalTabFunctions,
      };

      app.mount($el);
      vApps.push(app);
    }
  });
  window.vApps = vApps;
  return vApps;
};

export default (config) => {
  apps.push(config);

  // It is important to not pass an anonymous function here,
  // so that these events get bound only once.
  document.addEventListener('DOMContentLoaded', initApps);
};
