export const foxtagSitemap = {
  administration: {
    labelKey: 'nav.main.administration', href: '/organizations/settings',
  },
  'administration/installation-types': {
    labelKey: 'nav.admin.installation-types', icon: 'installation_type', href: '/installation_types', roles: ['admin'],
  },
  'administration/articles': {
    labelKey: 'nav.admin.articles', icon: 'articles', href: '/articles', roles: ['admin', 'manage'],
  },
  'administration/contacts': {
    labelKey: 'nav.admin.contacts', icon: 'contact', href: '/contacts', roles: ['admin', 'manage'],
  },
  'administration/labels': {
    labelKey: 'nav.admin.labels', icon: 'labels', href: '/organizations/features', roles: ['admin', 'manage'],
  },
  'administration/jobs': {
    labelKey: 'nav.admin.jobs', icon: 'job_settings', href: '/organizations/jobs', roles: ['admin'],
  },
  'administration/reports': {
    labelKey: 'nav.admin.reports', icon: 'report_logo_settings', href: '/organization_configs/reports', roles: ['admin'],
  },
  'administration/installation-templates': {
    labelKey: 'nav.admin.installation_templates', icon: 'installation_template', href: '/installation_templates', roles: ['admin', 'manage'],
  },
  'administration/import': {
    labelKey: 'nav.admin.import', icon: 'import', href: '/imports/articles/new', roles: ['admin', 'manage'],
  },
  'administration/organization': {
    labelKey: 'nav.admin.organization', icon: 'organization_settings', href: '/organizations/my', roles: ['admin'],
  },
  'administration/emails': {
    labelKey: 'nav.admin.emails', icon: 'email', href: '/organization_configs/emails', roles: ['admin'],
  },
  'administration/calendar': {
    labelKey: 'nav.admin.calendar', icon: 'calendar', href: '/organization_configs/calendar', roles: ['admin'],
  },
  'administration/app': {
    labelKey: 'nav.admin.app', icon: 'app_settings', href: '/organization_configs/app', roles: ['admin'],
  },
  'administration/users': {
    labelKey: 'nav.admin.users', icon: 'team', href: '/organizations/ORGANIZATION_ID/users', roles: ['admin'],
  },
  'administration/licenses': {
    labelKey: 'nav.admin.licenses', icon: 'license', href: '/licenses', roles: ['admin'],
  },
  'administration/integrations': {
    labelKey: 'nav.admin.integrations', icon: 'integration', href: '/organizations/ORGANIZATION_ID/integrations', roles: ['admin'],
  },
  'administration/integrations/api-keys': {
    labelKey: 'nav.admin.api', icon: 'api', href: '/organizations/ORGANIZATION_ID/api', roles: ['admin'],
  },
  'administration/integrations/event-logs': {
    labelKey: 'nav.admin.event-logs', icon: 'logs', href: '/organizations/ORGANIZATION_ID/events', roles: ['admin'],
  },
};
export default foxtagSitemap;

const anyRole = (user, roles) => {
  if (roles == null) { return false; }
  return roles.some((role) => user.roles.includes(role));
};

export const userSitemap = (user) => {
  const navs = {};

  Object.entries(foxtagSitemap).forEach(([key, nav]) => {
    if (anyRole(user, nav.roles)) {
      foxtagSitemap[key].href = foxtagSitemap[key].href.replace('ORGANIZATION_ID', user.organization_id);
      navs[key] = foxtagSitemap[key];
    }
  });

  return navs;
};
