/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

const defaultMainMapFilter = {
  installationTypeId: null,
  checkStatus: null,
  replacementStatus: null,
  technician: null,
  customerId: null,
};

const defaultCustomersListFilter = {
  letter: null,
  labelId: null,
  query: null,
};

const defaultJobsListFilter = {
  timerange: null,
  status: null,
  technicianId: null,
  locationId: null,
  installationTypeId: null,
  labelId: null,
};

const defaultLocationsListFilter = {
  query: null,
  installationTypeId: null,
  zip: null,
  dueTimerange: 'none',
  replacementTimerange: 'none',
  passive: null,
  labelId: null,
  technicianId: null,
};

const defaultIncidentsListFilter = {
  timerange: null,
  severity: null,
  assignedJob: null,
  status: null,
  customerId: null,
  locationId: null,
  installationId: null,
};

const defaultPagination = {
  page: 1,
  limit: 25,
};

export const useCurrentUserStore = defineStore('currentUser', {
  state: () => ({
    currentUser: useLocalStorage('currentUser', {}),
    mainMapFilter: useLocalStorage('mainMapFilter', defaultMainMapFilter),
    customersListFilter: useLocalStorage('customersListFilter', defaultCustomersListFilter),
    jobsListFilter: useLocalStorage('jobsListFilter', defaultJobsListFilter),
    locationsListFilter: useLocalStorage('locationsListFilter', defaultLocationsListFilter),
    incidentsListFilter: useLocalStorage('incidentsListFilter', defaultIncidentsListFilter),
    paginations: useLocalStorage('paginations', {}),
  }),
  actions: {
    change(newCurrentUser) {
      if (this.currentUser.id !== newCurrentUser.id) {
        // User has changed, we reset all stored filters
        this.mainMapFilter = defaultMainMapFilter;
        this.customersListFilter = defaultCustomersListFilter;
        this.jobsListFilter = defaultJobsListFilter;
        this.locationsListFilter = defaultLocationsListFilter;
        this.incidentsListFilter = defaultIncidentsListFilter;
        this.paginations = {};
      }

      this.currentUser = newCurrentUser;
    },
    reset() {
      this.currentUser = null;

      this.mainMapFilter = defaultMainMapFilter;
      this.customersListFilter = defaultCustomersListFilter;
      this.jobsListFilter = defaultJobsListFilter;
      this.locationsListFilter = defaultLocationsListFilter;
      this.incidentsListFilter = defaultIncidentsListFilter;
      this.paginations = {};
    },
    updatePaginationFromMeta(page, meta) {
      this.paginations[page] = {
        page: meta.current_page,
        limit: meta.limit,
      };
    },
    updateFilter(page, filter) {
      switch (page) {
        case 'map':
          this.mainMapFilter = filter;
          break;
        case 'customers':
          this.customersListFilter = filter;
          break;
        case 'jobs':
          this.jobsListFilter = filter;
          break;
        case 'locations':
          this.locationsListFilter = filter;
          break;
        case 'incidents':
          this.incidentsListFilter = filter;
          break;
        default:
          break;
      }
    },
    pagination(page) {
      return this.paginations[page] || defaultPagination;
    },
    filter(page) {
      switch (page) {
        case 'map':
          return this.mainMapFilter;
        case 'customers':
          return this.customersListFilter;
        case 'jobs':
          return this.jobsListFilter;
        case 'locations':
          return this.locationsListFilter;
        case 'incidents':
          return this.incidentsListFilter;
        default:
          return {};
      }
    },
  },
});
