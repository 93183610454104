import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import NewIncidentModal from 'apps/incidents/new/new_incident_modal.vue';

const openNewIncidentModal = (initInstallationId, initThingId) => new Promise((resolve) => {
  const modalInstance = useModal({
    component: VueFinalModal,
    slots: {
      default: useModalSlot({
        component: NewIncidentModal,
        attrs: {
          initInstallationId,
          initThingId,
          onClose() {
            modalInstance.close();
          },
          onCreated() {
            resolve();
          },
        },
      }),
    },
  });
  modalInstance.open();
});

export default openNewIncidentModal;
