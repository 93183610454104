import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import ConfirmModal from 'components/confirm_modal.vue';

const confirmViaModal = (attrs) => new Promise((resolve) => {
  const actionLabel = attrs.action || 'Löschen';
  const confimationWord = attrs.confirmationWord || 'löschen';
  const requireInput = attrs.requireInputConfirmation || false;

  const modalInstance = useModal({
    component: VueFinalModal,
    slots: {
      default: useModalSlot({
        component: ConfirmModal,
        attrs: {
          title: attrs.title,
          message: attrs.message,
          action: actionLabel,
          confirmationWord: confimationWord,
          requireInputConfirmation: requireInput,
          onClose() {
            modalInstance.close();
          },
          onConfirmed() {
            modalInstance.close();
            resolve();
          },
        },
      }),
    },
  });
  modalInstance.open();
});

export default confirmViaModal;
