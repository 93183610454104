<template>
  <ExternalLinkButton v-if="link" :href="link" style="margin-right: 8px;" />
  <span v-if="labels && labels.length">
    <span v-for="label in labels" :key="label.id" style="line-height: 24px;">
      <span class="fx-label" :style="styleForLabel(label)">{{ label.name }}</span>&shy;
    </span>
  </span>
</template>

<script>
import ExternalLinkButton from 'components/external_link_button.vue';

export default {
  name: 'Labels',
  components: {
    ExternalLinkButton,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    link: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    styleForLabel(label) {
      return `color: ${label.text_color}; background-color: ${label.bg_color};`;
    },
  },
};
</script>
