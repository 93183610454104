<template>
  <span>
    <OnClickOutside @trigger="close">

      <div class="fx-select disabled">
        <button
          type="button"
          class="btn-select"
          :class="{ readonly: readonly }"
          @click="toggleAndFocus"
        >
          <div class="buttonLabel" :class="classForLabel" :style="{ color: readonly ? 'grey' : 'black' }">
            <i class="fx-select-icon" :class="icon(iconId)" />
            {{ label }}
            <span v-if="subline" class="secondary"><br>{{ subline }}</span>
            <span class="caret" :style="{ borderTopColor: readonly ? 'grey' : 'black'}" />
          </div>
        </button>

        <div v-if="isOpen && !readonly" class="fx-filter-dropdown show" style="padding: 5px 0;">
          <div style="margin:0; padding: 7px 15px 7px 15px; border-bottom: 1px solid #ddd;">
            <input
              ref="searchInput"
              v-model="query"
              type="text"
              :placeholder="$t(`comp.select.${i18nBaseKey}.search-placeholder`)"
              style="font-size: .8rem; padding: 5px; height: unset; margin: 0;"
              @keyup="onSearchChanged"
            >
          </div>

          <div style="max-height: 400px; overflow-y:scroll">
            <div class="fx-filter-item">
              <a @click.exact="resetSelection"><i>{{ defaultLabel }}</i></a>
            </div>
            <div v-for="item in options" :key="item.id" class="fx-filter-item">
              <a :class="classForItem(item)" @click.exact="selectAndEmit(item)">
                <div style="display: inline-block;">
                  <strong>{{ item.number }}</strong> {{
                    item.option_label
                  }}<br><span class="secondary">{{ item.subline }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </OnClickOutside>
  </span>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import foxtagIcons from 'initializers/icons';

import { OnClickOutside } from '@vueuse/components';
import { useFilterDropdown } from 'apps/application/filter_dropdown_logic';

export default {
  components: {
    OnClickOutside,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    defaultLabel: {
      type: String,
      default: 'Keine Auswahl',
    },
    loadOptionsUrl: {
      type: String,
      required: true,
    },
    loadOptionsParams: {
      type: Object,
      default: () => ({}),
    },
    iconId: {
      type: String,
      default: null,
    },
    i18nBaseKey: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'changed'],
  setup(props, { emit }) {
    const {
      isOpen, options, selection, label, subline,
      classForItem, classForLabel, select, resetSelection, inSelection, inOptions, toggleOpen, close, selectionString, parseSelectionString,
    } = useFilterDropdown(props.defaultLabel, emit);
    return {
      isOpen,
      options,
      selection,
      label,
      subline,
      classForLabel,
      classForItem,
      select,
      resetSelection,
      inSelection,
      inOptions,
      toggleOpen,
      close,
      selectionString,
      parseSelectionString,
    };
  },
  data() {
    return {
      query: null,
    };
  },
  watch: {
    loadOptionsParams: {
      handler() {
        this.loadDefaultOptions();
      },
      deep: true,
    },
    modelValue: {
      handler(newVal) {
        if (newVal == null || newVal === '') {
          this.selection = [];
        } else {
          const parseResult = this.parseSelectionString(newVal);
          this.selection = parseResult.selection;

          // in case modalValue is not initialized (e.g. async loading
          // not finished) when mounting this component, we need to load the
          // selected option
          this.loadAndAddSelectedOption(this.selection[0]);
        }
      },
      deep: true,
    },
    loadOptionsUrl: {
      handler() {
        this.loadDefaultOptions();
      },
    },
  },
  mounted() {
    if (this.modelValue == null || this.modelValue === '') {
      this.selection = [];
    } else {
      const parseResult = this.parseSelectionString(this.modelValue);
      this.selection = parseResult.selection;
    }
    this.loadDefaultOptions();
  },
  methods: {
    toggleAndFocus() {
      this.toggleOpen();

      if (this.isOpen && !this.readonly) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    icon(id) {
      return foxtagIcons[id];
    },
    selectAndEmit(item) {
      this.select(item);
      this.$emit('changed', this.selectionString('or')); // fixed logic (no multiselect)
    },
    onSearchChanged() {
      this.debouncedSearch(this.query);
    },
    // eslint-disable-next-line func-names
    debouncedSearch: _.debounce(function (query) {
      this.searchOptions(query);
    }, 250),
    searchOptions(query) {
      const params = { ...this.loadOptionsParams };
      params.query = query;

      axios.get(this.loadOptionsUrl, {
        params,
      }).then((response) => {
        this.options = response.data.options;
      }).catch((err) => Flash.error(err));
    },
    loadAndAddSelectedOption(id) {
      // --- do not add if already in options
      if (this.inOptions(id)) {
        return;
      }

      const params = { ...this.loadOptionsParams };
      params.id = id;
      axios.get(this.loadOptionsUrl, {
        params,
      }).then((response) => {
        if (response.data.options.length > 0) {
          this.options.unshift(response.data.options[0]);
        }
      }).catch((err) => Flash.error(err));
    },
    loadDefaultOptions() {
      const params = { ...this.loadOptionsParams };
      axios.get(this.loadOptionsUrl, {
        params,
      }).then((response) => {
        this.options = response.data.options;

        // --- the list of default options may not include
        // --- the selected option, so we need to make sure
        // --- its in the list
        if (this.selection.length === 1) {
          this.loadAndAddSelectedOption(this.selection[0]);
        } else if (this.selection.length === 0 && this.options.length === 1) {
          this.selectAndEmit(this.options[0]);
        }
      }).catch((err) => Flash.error(err));
    },
  },
};
</script>
