<template>
  <div class="photo-grid-previews-col">
    <div v-for="(photo, index) in localPhotos" :key="photo.id">
      <img :src="photo.thumb_url" style="cursor: pointer; width: 100%; height: 100%; border: 1px solid #aaa; object-fit: cover; aspect-ratio: 4 / 3; border-radius: 4px;" @click="$emit('click')">
      <!--<span style="position: relative; left: 5px; bottom: 28px; font-size: .8rem;">
        <span style="background: #eeeeeeaa; padding: 3px 6px; border-radius: 9999px;"><i class="fas fa-cube"></i></span>
        <span style="background: #eeeeeeaa; padding: 3px 6px; border-radius: 9999px;">{{ formatDate(photo.taken_at, 'DD.MM.YYYY') }}</span>
    </span>-->
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter } from 'mixins/formatters';

export default {
  name: 'PhotosPreview',
  components: {
  },
  mixins: [FeatureFlipper, DateFormatter],
  props: {
    title: {
      type: String,
      default: null,
    },
    photos: {
      type: Array,
      required: true,
    },
    photoableId: {
      type: String,
    },
    photoableType: {
      type: String,
    },
    colCount: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      showAddPhotos: false,
      localPhotos: [],
    };
  },
  computed: {
    gridClasses() {
      if (this.colCount == 3) {
        return 'photo-grid-3-col';
      }
      return 'photo-grid-4-col';
    },
  },
  mounted() {
    this.localPhotos = this.photos;
  },
  methods: {
  },
};
</script>
