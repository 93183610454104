<template>
  <div class="fx-filter">
    <OnClickOutside @trigger="isOpen = false">
    <span v-for="assignedLabel in localAssignedLabels" :key="assignedLabel.id">
      <span class="fx-label-large" :style="styleForLabel(assignedLabel)">{{ assignedLabel.name }}
        <a v-if="canEdit" class="delete" @click="deleteAssignmentViaApi(assignedLabel)">
          <i v-tippy="{ placement: 'top', duration: 100, arrow: true }" class="fas fa-times" title="Label entfernen" />
        </a>
      </span>&shy;
    </span>
    <div class="fx-filter">
    <span class="fx-label-btn" style="cursor: pointer;" @click="isOpen = !isOpen"><i class="fas fa-plus fa-xs" /> <i class="fas fa-tag" /></span>

    <div class="fx-filter-dropdown" :class="{ 'show': isOpen }" style="padding: 5px 0; margin-top: 5px; font-size: .9rem;">
      <div style="max-height: 600px; overflow-y:scroll">
        <div v-for="label in availableLabels" :key="label.id" style="padding: 4px 12px;">
          <a class="no-color no-hover" style="width: 100%; display: inline-block;" @click="assignLabelViaApi(label)">
            <div :style="styleForColorBlock(label)" /> {{ truncate(label.name, 25) }} <i
              v-if="label.show_in_app"
              class="fas fa-mobile-alt"
            /> <i v-if="label.show_in_portal" class="far fa-globe" />
          </a>
        </div>
      </div>
      <div v-if="!availableLabels.length" style="padding: 4px 12px;" class="secondary">
        Keine Labels verfügbar
      </div>
      <div style="padding: 3px 8px; margin-top: 5px; border-top: 1px solid #ccc; padding-top: 8px;">
        <i class="fas fa-plus fa-fw" /> <a class="no-color" @click="openNewLabelModal">Label hinzufügen</a><br>
      </div>
      <div style="padding: 3px 8px;">
        <i class="fas fa-cog fa-fw" /> <a :href="adminPath" class="no-color">Labels bearbeiten</a>
      </div>
    </div>
    </div>
    </OnClickOutside>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';

import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { ref } from 'vue';
import { TruncateFormatter } from 'mixins/formatters';
import { OnClickOutside } from '@vueuse/components';
import EditLabelModal from 'apps/labels/index/edit_label_modal.vue';

export default {
  name: 'AssignLabels',
  components: {
    OnClickOutside,
  },
  mixins: [TruncateFormatter],
  props: {
    lablableId: {
      type: String,
      required: true,
    },
    presetLabels: {
      type: Array,
      required: true,
    },
    labelType: {
      type: String,
      default: 'installation',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const localLabels = ref([]);
    const localAssignedLabels = ref([]);
    const isOpen = ref(false);
    const serverErrors = ref([]);

    const assignLabelViaApi = (label) => {
      serverErrors.value = [];

      axios.post(`/labels/${label.id}/assign.json`, {
        lablable_id: props.lablableId,
        lablable_type: props.labelType,
      }).then(() => {
        localAssignedLabels.value.push(label);
        Flash.info('Label gespeichert');
        isOpen.value = false;
        emit('changed');
      }).catch(() => {
        Flash.error('Label konnte nicht gespeichert werden');
      });
    };

    const openNewLabelModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditLabelModal,
            attrs: {
              labelType: props.labelType,
              onClose() {
                modalInstance.close();
              },
              onCreated(newLabel) {
                modalInstance.close();
                localLabels.value.push(newLabel);
                assignLabelViaApi(newLabel);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      serverErrors,
      localLabels,
      localAssignedLabels,
      openNewLabelModal,
      assignLabelViaApi,
      isOpen,
    };
  },
  computed: {
    availableLabels() {
      const labels = this.localLabels.slice();
      this.localAssignedLabels.forEach((assignedLabel) => {
        const index = _.findIndex(labels, { id: assignedLabel.id });
        if (index > -1) {
          labels.splice(index, 1);
        }
      });
      return labels;
    },
    adminPath() {
      return `/organizations/features#${this.labelType}`;
    },
  },
  mounted() {
    this.localAssignedLabels = this.presetLabels;
    if (this.canEdit) {
      this.loadLabels();
    }
  },
  methods: {
    styleForLabel(label) {
      return `color: ${label.text_color}; background-color: ${label.bg_color};`;
    },
    styleForColorBlock(label) {
      return `display:inline-block; width: 16px; height: 16px; background-color: ${label.bg_color}; vertical-align: bottom; border-radius: 5px; margin-bottom: 2px; margin-right: 5px;`;
    },

    deleteAssignmentViaApi(label) {
      this.serverErrors = [];
      const that = this;
      axios.post(`/labels/${label.id}/remove.json`, {
        lablable_id: this.lablableId,
        lablable_type: this.labelType,
      }).then(() => {
        const index = that.findAssignedLabelIndexInList(label.id);
        that.localAssignedLabels.splice(index, 1);
        Flash.info('Label wurde entfernt');
        this.$emit('changed');
      }).catch(() => {
        Flash.error('Label konnte nicht entfernt werden');
      });
    },
    findAssignedLabelIndexInList(assignedLabelId) {
      return _.findIndex(this.localAssignedLabels, { id: assignedLabelId });
    },
    loadLabels() {
      const that = this;
      axios.get('/labels', {
        params: {
          limit: 500,
          label_type: this.labelType,
        },
      }).then((response) => {
        that.localLabels = response.data.labels;
      }).catch(() => {
        Flash.error('Label-Informationen konnten nicht geladen werden');
      });
    },
  },
};
</script>
