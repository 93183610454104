<template>
  <span>
    <strong>{{ localizedStartDate }}</strong> {{ startDateTime() }}
    <span v-if="!hideEndDate()">
      - <strong v-if="localizedStartDate != localizedEndDate">{{ localizedEndDate }}</strong> {{ endDateTime() }}
    </span>
  </span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'JobDuration',
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localizedStartDate: null,
      localizedEndDate: null,
    };
  },
  watch: {
    job: {
      handler() {
        this.initLocalDate();
      },
      deep: true,
    },
  },
  mounted() {
    this.initLocalDate();
  },
  methods: {
    initLocalDate() {
      const dayFormat = 'dd DD.MM.YYYY';
      this.localizedStartDate = moment(this.job.planned_at).format(dayFormat);
      if (this.job.all_day) {
        this.localizedEndDate = moment(this.job.enddate).subtract(1, 'days').format(dayFormat);
      } else {
        this.localizedEndDate = moment(this.job.enddate).format(dayFormat);
      }
    },
    hideEndDate() {
      // --- dates are exactly the same.
      if (this.job.planned_at === this.job.enddate) {
        return true;
      }

      return (this.job.all_day && (this.localizedStartDate === this.localizedEndDate));
    },
    startDate() {
      return moment(this.job.planned_at).format('DD.MM.YYYY');
    },
    startDateTime() {
      if (!this.job.all_day) {
        return `${moment(this.job.planned_at).format('HH:mm')} Uhr`;
      }
      return null;
    },
    endDateTime() {
      if (!this.job.all_day) {
        return `${moment(this.job.enddate).format('HH:mm')} Uhr`;
      }
      return null;
    },
  },
};
</script>
