import axios from 'axios';

const Api = {
  getCustomersForMultiSelect(query) {
    return axios.get('/customers/for_multi_select', {
      params: {
        query,
      },
    });
  },
  getAllIncidents(
    offset,
    limit,
    severity,
    status,
    assignedJob,
    installationTypeId,
    installationId,
    locationId,
    customerId,
    timerange,
    labelId,
    withThingDetails,
  ) {
    return axios.get('/incidents/filtered', {
      params: {
        severity,
        status,
        assigned_job: assignedJob,
        installation_type_id: installationTypeId,
        installation_id: installationId,
        location_id: locationId,
        customer_id: customerId,
        label_id: labelId,
        timerange,
        with_thing_details: withThingDetails,
        offset,
        limit,
      },
    });
  },
  getNextJob(data) {
    return axios.get(`/incidents/${data.incidentId}/next_job`);
  },
  getAssignedJob(data) {
    return axios.get(`/incidents/${data.incidentId}/assigned_job`);
  },
  assignIncidentToJob(data) {
    const params = {
      assigned_job_id: data.assignedJobId,
    };
    return axios.patch(`/incidents/${data.incidentId}/update_assigned_job`, params);
  },
  createIncident(data) {
    const formData = new FormData();

    if (data.file) {
      formData.append('file', data.file);
    }

    formData.append('installation_id', data.installationId);
    formData.append('incident_type_id', data.incidentTypeId);
    formData.append('thing_id', data.thingId);
    formData.append('severity', data.severity);
    formData.append('note', data.note || '');
    formData.append('description', data.description || '');
    formData.append('link', data.link || '');
    formData.append('statusdate', data.statusdate);
    formData.append('statusdate_time', data.statusdateTime || '');
    formData.append('reported_by', data.reportedBy || '');
    if (data.inboxItem) {
      formData.append('inbox_item_id', data.inboxItem.id || '');
    }

    return axios.post('/incidents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteIncident(incidentId) {
    return axios.delete(`/incidents/${incidentId}`);
  },
  createIncidentStatus(data) {
    const formData = new FormData();

    if (data.file) {
      formData.append('file', data.file);
    }

    formData.append('status', data.status);
    formData.append('note', data.note || '');
    formData.append('resolution', data.resolution || '');
    formData.append('statusdate', data.statusdate);

    return axios.post(`/incidents/${data.incidentId}/incident_statuses`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updateIncident(data) {
    const params = {
      incident_type_id: data.incidentTypeId,
      severity: data.severity,
      reported_by: data.reportedBy,
      description: data.description,
      link: data.link,
    };

    return axios.patch(`/incidents/${data.incidentId}`, params);
  },
  updateIncidentStatus(data) {
    const params = {
      note: data.note,
      status: data.status,
      resolution: data.resolution,
      statusdate: data.statusdate,
    };

    return axios.patch(`/incidents/${data.incidentId}/incident_statuses/${data.incidentStatusId}`, params);
  },
  deleteIncidentStatus(incidentId, incidentStatusId) {
    return axios.delete(`/incidents/${incidentId}/incident_statuses/${incidentStatusId}`);
  },
  deleteAttachment(incidentId, incidentStatusId, attachmentId) {
    return axios.delete(`/incidents/${incidentId}/incident_statuses/${incidentStatusId}/destroy_attachment/${attachmentId}`);
  },
};

export default Api;
