<template>
  <div class="fx-modal-content-medium" style="min-height: 50px;">
    <SimpleTypeahead
      id="command-input"
      autocomplete="false"
      placeholder="Start typing command..."
      :min-input-length="1"
      :items="typeAheadItems"
      @select-item="processCommand"
    />
    <i v-if="isProcessing" class="far fa-spinner fa-spin" aria-hidden="true" />
  </div>
</template>

<script setup>
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import { ref, onMounted } from 'vue';

import openNewJobModal from 'helpers/open_new_job_modal';
import openNewIncidentModal from 'helpers/open_new_incident_modal';

const emit = defineEmits(['close']);

const typeAheadItems = ref([]);
const isProcessing = ref(false);

// --- define actions used by command before this line

const commands = [
  // { label: 'Backoffice', url: '/accounts/to_backoffice' }, TODO: implement roles
  { label: 'Auftrag planen', action: openNewJobModal },
  { label: '> Kunden', url: '/customers' },
  { label: '> Anlagen', url: '/locations' },
  { label: '> Störungen', url: '/incidents' },
  { label: '> Störung erfassen', action: openNewIncidentModal },
  { label: '> Start', url: '/users/start' },
  { label: '> Administration > Artikel', url: '/articles' },
  { label: '> Administration > Wartungsanwendungen', url: '/installation_types' },
  { label: '> Administration > Mitarbeiter', url: '/organizations/users' },
  { label: '> Administration > Kontakte', url: '/contacts' },
  { label: '> Administration > Kundenservice einladen', url: '/organizations/users' },
  { label: '> Persönliche Einstellungen', url: '/accounts/my' },
  { label: '> Benachrichtigungen', url: '/notifications' },
];

const findCommand = (label) => {
  let result = null;
  commands.forEach((command) => {
    if (command.label.toLowerCase() === label.toLowerCase()) {
      result = command;
    }
  });
  return result;
};

const processCommand = (label) => {
  const command = findCommand(label);

  // --- go to url
  if (command && command.url != null) {
    isProcessing.value = true; // show spinner while loading page
    window.location = command.url;
  }

  // --- call method
  if (command && command.action !== undefined) {
    command.action();
    emit('close');
  }
};

onMounted(() => {
  isProcessing.value = false;

  commands.forEach((command) => {
    typeAheadItems.value.push(command.label);
  });

  document.getElementById('command-input').focus();
});
</script>
