<template>
  <div style="margin-bottom: .6rem;">
    <button
      class="fx-btn-secondary fx-dropdown-section"
      :class="btnClasses"
      :disabled="!enabled"
      @click="toggle()"
    >
      <i v-if="collapsed" class="fas fa-caret-down" style="float: right;" />
      <i v-if="!collapsed" class="fas fa-caret-up" style="float: right;" />
      {{ title }}
    </button>
    <div v-if="!collapsed" class="fx-dropdown-section">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DropdownSection',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    initCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    btnClasses() {
      if (!this.enabled) {
        return 'fx-btn-disabled';
      }

      if (!this.collapsed) {
        return 'fx-dropdown-section_active';
      }
      return '';
    },
  },
  mounted() {
    this.collapsed = this.initCollapsed;
  },
  methods: {
    toggle() {
      if (!this.enabled) {
        return;
      }
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
