<template>
  <div id="job-quickview-modal" class="fx-modal-content-large">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <div v-if="job" style="float: right; margin-right: 1.5rem;">
      <span style="color: #666;">
        <a v-if="job.incidents_count" class="no-color" :href="jobUrl('tab-incidents')"><span
          style="margin-right: 0.4rem; white-space: nowrap;"
        ><i class="fas fa-exclamation-triangle" /> {{
          job.incidents_count }}</span>
        </a>
        <a v-if="job.photos_count" class="no-color" :href="jobUrl('tab-photos')">
          <span style="margin-right: 0.4rem; white-space: nowrap;">
            <i class="fas fa-camera" /> {{ job.photos_count }}
          </span>
        </a>
        <a v-if="job.notes_count" class="no-color" :href="jobUrl('tab-notes')">
          <span style="margin-right: 0.4rem; white-space: nowrap;">
            <i class="fas fa-comment" /> {{ job.notes_count }}
          </span>
        </a>
      </span>
      <a :href="jobUrl()" class="fx-btn-secondary">Details</a>
    </div>
    <span v-if="loading">loading ...</span>

    <template v-if="job">
      <h2 class="fx">
        <strong>{{ job.number }}</strong> {{ job.job_type.name }} {{ formatDate(job.planned_at,
                                                                                'DD.MM.YYYY') }}
      </h2>
      <LabelsAssign
        :preset-labels="job.labels"
        label-type="job"
        :lablable-id="job.id"
        :can-edit="currentUser.policies.canEditProLabels"
        :link="job.link"
        @changed="$emit('changed', job)"
      />
      <div class="fx-grid">
        <div class="fx-col-notop" style="font-size: .9rem; padding-left: 0;">
          <table class="material top-align">
            <colgroup>
              <col style="width: 110px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th class="pl-0">
                  Datum
                </th>
                <td>
                  <JobDuration :job="job" />
                </td>
              </tr>

              <tr>
                <th class="pl-0">
                  Mitarbeiter
                </th>
                <td>
                  <span>
                    <template v-if="job.pooling"><img
                      src="/jeder.svg"
                      height="24px"
                      width="42px"
                      style="vertical-align: middle;"
                      title="Jeder Nutzer kann diesen Auftrag durchführen"
                    ></template>
                    <template v-else>
                      <div v-for="user in job.assigned_users" :key="user.id">
                        <img
                          :src="user.avatar_path"
                          width="20px"
                          height="20px"
                          style="vertical-align: middle;"
                        > <span
                          style="vertical-align: middle;"
                        >{{ user.full_name }}</span>
                      </div>
                    </template>
                  </span>
                </td>
              </tr>

              <tr>
                <th class="pl-0">
                  Hinweise
                </th>
                <td>
                  {{ truncate(job.instructions, 500) }}
                  <span v-if="!job.instructions" class="secondary">keine Hinweise</span>
                </td>
              </tr>

              <tr>
                <th class="pl-0">
                  Kunde
                </th>
                <td>
                  <template v-if="job.customer">
                    <a :href="customerPath(job.customer.id)" class="no-color">
                      <Labels :labels="job.customer.labels" />
                      <div class="secondary">{{ job.customer.number }}</div>
                      {{ job.customer.name }}
                    </a>
                  </template>
                  <template v-else>
                    <span class="secondary">Kein Kunde zugeordnet</span>
                  </template>
                </td>
              </tr>

              <tr>
                <th class="pl-0">
                  Objekt
                </th>
                <td>
                  <Location :location="job.installation.location" :link="true" />
                </td>
              </tr>
              <tr>
                <th class="pl-0">
                  Anlage
                </th>
                <td>
                  <Labels :labels="job.installation.labels" />
                  <Installation :installation="job.installation" :link="true" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="fx-col-notop" style="font-size: .9rem; padding-left: 0;">
          <table class="material top-align">
            <colgroup>
              <col style="width: 110px;">
              <col>
            </colgroup>
            <tbody>
              <tr class="middle-align">
                <th class="pl-0">
                  Aktueller Status
                </th>
                <td>
                  <span style="line-height: 36px;"><span class="fx-status" :class="job.status">{{ $t('job.status.' +
                    job.status) }}</span></span>
                </td>
              </tr>

              <tr>
                <th class="pl-0">
                  Veröffentlicht
                </th>
                <td>
                  <span v-if="job.published_at">Veröffentlich am <strong>
                    {{ formatDate(job.published_at, 'DD.MM.YYYY HH:mm') }} Uhr</strong></span>
                  <span v-else class="secondary">nicht veröffentlicht</span>
                </td>
              </tr>

              <tr>
                <th class="pl-0">
                  Berichte
                </th>
                <td>
                  <a v-for="attachment in job.attachments" :key="attachment.id" :href="attachment.download_url">
                    <div class="fx-file-pane">
                      <div>
                        <img :src="attachment.preview_url" class="fx-file-pane"><br>
                        <span class="fx-file-pane-label">{{ attachment.label }}</span>
                      </div>
                    </div>
                  </a>
                  <div v-if="job.attachments.length == 0" class="secondary">
                    Keine Berichte verfügbar
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { useCurrentUserStore } from 'stores/current_user';

import FoxtagPaths from 'mixins/foxtag_paths';
import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter, TruncateFormatter } from 'mixins/formatters';

import LabelsAssign from 'apps/labels/assign/index.vue';
import Location from 'components/partials/location.vue';
import Installation from 'components/partials/installation.vue';
import Labels from 'components/partials/labels.vue';
import JobDuration from 'components/partials/job_duration.vue';

export default {
  name: 'JobQuickViewModal',
  components: {
    Location,
    Installation,
    Labels,
    LabelsAssign,
    JobDuration,
  },
  mixins: [FoxtagPaths, FeatureFlipper, DateFormatter, TruncateFormatter],
  props: {
    jobId: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'changed'],
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return {
      loading: true,

      job: null,
    };
  },
  mounted() {
    if (this.jobId == null) { return; }
    const that = this;
    axios.get(`/jobs/${this.jobId}/details.json`).then((response) => {
      that.job = response.data.job_details;
      that.loading = false;
    }).catch(() => {
      that.loading = false;
      that.error = true;
    });
  },
  methods: {
    jobUrl(anchor) {
      let url = `/jobs/${this.jobId}`;
      if (anchor) {
        url = `${url}#${anchor}`;
      }
      return url;
    },
  },
};
</script>
<style>
table.material th.pl-0 {
  padding-left: 0;
}
</style>
