<template>
  <div style="display: flex">
    <div style="flex: 1; margin-right: .5rem;">
      <input
        id="dat_date"
        v-model="date"
        name="dat_date"
        type="text"
        @blur="parseAndEmit()"
      >
    </div>
    <div style="flex: 1; margin-left: .5rem;">
      <input
        id="dat_time"
        v-model="time"
        type="text"
        name="dat_time"
        placeholder="optional"
        @blur="parseAndEmit()"
      >
    </div>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'DateAndTimeInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['changed'],
  data() {
    return {
      date: moment().format('DD.MM.YYYY'),
      time: moment().format('HH:mm'),
    };
  },
  mounted() {
    let initialDate = moment();
    if (this.modelValue != null) {
      initialDate = moment(this.modelValue);
    }

    this.date = initialDate.format('DD.MM.YYYY');
    this.time = initialDate.format('HH:mm');

    this.parseAndEmit();
  },
  methods: {
    parseAndEmit() {
      const newVal = moment(`${this.date} ${this.time}`, 'DD.MM.YYYY HH:mm');
      this.$emit('changed', newVal.format());
    },
  },
};
</script>
