<template>
  <div>
    <a v-if="link" :href="locationUrl" class="no-color">
      <div class="secondary">{{ location.number }}</div>
      {{ location.name }}
      <Address :address="location.active_address" />
    </a>
    <template v-else>
      <div class="secondary">
        {{ location.number }}
      </div>
      {{ location.name }}
      <Address :address="location.active_address" />
    </template>
  </div>
</template>

<script>
import Address from 'components/partials/address.vue';

export default {
  components: {
    Address,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    link: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    locationUrl() {
      return `/locations/${this.location.id}`;
    },
  },
};
</script>
