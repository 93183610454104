<template>
  <div>
    <div style="display: inline-block; white-space: nowrap;">
      <div style="display: inline-block; width: 150px;">
        <VueDatePicker
          id="startdate-date"
          v-model="localDuration.startDate"
          :locale="$i18n.locale"
          :auto-apply="true"
          model-type="yyyy-MM-dd"
          :enable-time-picker="false"
          :format="'dd.MM.yyyy'"
        />
      </div>

      <div v-if="!localDuration.allDay" style="display: inline-block; width: 65px; margin-left: 5px;">
        <time-input id="startdate-time" v-model="localDuration.startDateTime" @input="event => localDuration.startDateTime = event" />
      </div>
    </div>
    bis
    <div style="display: inline-block; white-space: nowrap;">
      <div style="display: inline-block; width: 150px;">
        <VueDatePicker
          id="enddate-date"
          v-model="localDuration.endDate"
          :locale="$i18n.locale"
          :auto-apply="true"
          model-type="yyyy-MM-dd"
          :enable-time-picker="false"
          :format="'dd.MM.yyyy'"
        />
      </div>
      <div v-if="!localDuration.allDay" style="display: inline-block; width: 65px; margin-left: 5px;">
        <time-input id="enddate-time" v-model="localDuration.endDateTime" @input="event => localDuration.endDateTime = event" />
      </div>
    </div>

    <div style="padding-top: .2rem;">
      <input
        id="job-all-day"
        v-model="localDuration.allDay"
        type="checkbox"
        @click="resetTimeDefaults()"
      > <label
        for="job_all_day"
      >Ganztägig</label>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import TimeInput from 'components/time_input.vue';

export default {
  name: 'StartEnddateForm',
  components: {
    VueDatePicker,
    TimeInput,
  },
  props: {
    initStartDate: {
      type: String,
      required: true,
    },
    initEndDate: {
      type: String,
      required: true,
    },
    initAllDay: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['changed'],
  data() {
    return {
      localDuration: {
        startDate: null,
        startDateTime: null,
        endDate: null,
        endDateTime: null,
        allDay: true,
      },
    };
  },
  computed: {
    startDateWithTime() {
      return `${this.localDuration.startDate} ${this.localDuration.startDateTime}`;
    },
    endDateWithTime() {
      return `${this.localDuration.endDate} ${this.localDuration.endDateTime}`;
    },
  },
  watch: {
    localDuration: {
      handler(newVal) {
        if (moment(newVal.endDate).isBefore(this.localDuration.startDate)) {
          this.localDuration.endDate = this.localDuration.startDate;
        }
        this.emitValue();
      },
      deep: true,
    },
  },
  mounted() {
    this.localDuration.startDate = moment(this.initStartDate).format('YYYY-MM-DD');
    this.localDuration.startDateTime = moment(this.initStartDate).format('HH:mm');

    if (this.initAllDay) {
      this.localDuration.endDate = moment(this.initEndDate).subtract(1, 'days').format('YYYY-MM-DD');
    } else {
      this.localDuration.endDate = moment(this.initEndDate).format('YYYY-MM-DD');
    }
    this.localDuration.endDateTime = moment(this.initEndDate).format('HH:mm');

    this.localDuration.allDay = this.initAllDay;
  },
  methods: {
    resetTimeDefaults() {
      if (this.localDuration.allDay) {
        this.localDuration.startDateTime = '08:00';
        this.localDuration.endDateTime = '10:00';
      } else {
        this.localDuration.startDateTime = '00:00';
        this.localDuration.endDateTime = '00:00';
      }
    },
    emitValue() {
      let resultingDuration = {};

      if (this.localDuration.allDay) {
        const nextDayEndDate = moment(this.localDuration.endDate).add(1, 'days').format('YYYY-MM-DD');

        resultingDuration = {
          startDate: `${moment(this.localDuration.startDate).format('YYYY-MM-DD')}T00:00`,
          endDate: `${nextDayEndDate}T00:00`,
          allDay: true,
        };
      } else {
        resultingDuration = {
          startDate: `${moment(this.localDuration.startDate).format('YYYY-MM-DD')}T${this.localDuration.startDateTime}`,
          endDate: `${moment(this.localDuration.endDate).format('YYYY-MM-DD')}T${this.localDuration.endDateTime}`,
          allDay: false,
        };
      }

      this.$emit('changed', resultingDuration);
    },
  },
};
</script>
