import { ref, computed } from 'vue';
import _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export function useFilterDropdown(nothingSelectedLabel, emit) {
  const options = ref([]);
  const selection = ref([]);
  const isOpen = ref(false);
  const isMultiSelect = ref(false);
  const andConcat = '_';
  const orConcat = '|';
  const maxMultiSelect = 3;

  const inOptions = (id) => {
    const ixd = _.findIndex(options.value, { id });
    return ixd !== -1;
  };

  const labelFor = (id) => {
    if (id == null) {
      return nothingSelectedLabel;
    }

    const ixd = _.findIndex(options.value, { id });
    if (ixd === -1) {
      return nothingSelectedLabel;
    }

    const label = options.value[ixd].label;
    return (label.length > 150) ? `${label.substr(0, 150)}...` : label;
  };

  const sublineFor = (id) => {
    if (id == null) {
      return null;
    }

    const ixd = _.findIndex(options.value, { id });
    if (ixd === -1) {
      return null;
    }

    const subline = options.value[ixd].subline;
    if (subline) { // not all options have a subline key
      return (subline.length > 150) ? `${subline.substr(0, 150)}...` : subline;
    }
    return null;
  };

  const label = computed(() => {
    if (selection.value.length > 1) {
      return `Mehrfachauswahl (${selection.value.length}) `;
    }

    if (selection.value.length === 0) {
      return nothingSelectedLabel;
    }

    return labelFor(selection.value[0]);
  });

  const subline = computed(() => {
    if (selection.value.length > 0) {
      return sublineFor(selection.value[0]);
    }
    return null;
  });

  const classForLabel = computed(() => {
    if (selection.value.length === 0) {
      return 'dimmed';
    }
    return '';
  });

  const resetSelection = () => {
    selection.value = [];
    isOpen.value = false;
    isMultiSelect.value = false;
    emit('changed'); // _.join(selection.value, '|'));
  };
  const selectionString = (logic) => {
    if (logic === 'and') {
      return _.join(selection.value, andConcat);
    }
    return _.join(selection.value, orConcat);
  };

  const select = (item) => {
    selection.value = [item.id];
    isOpen.value = false;
  };

  const inSelection = (item) => selection.value.includes(item.id);

  const toggleMultiSelect = (item) => {
    if (inSelection(item)) {
      const idx = selection.value.indexOf(item.id);
      selection.value.splice(idx, 1);
    } else {
      if (selection.value.length >= maxMultiSelect) {
        selection.value.pop();
      }
      selection.value.push(item.id);
    }
  };

  const toggleOpen = () => {
    isOpen.value = !isOpen.value;
  };

  const close = () => {
    isOpen.value = false;
  };

  const classForItem = (item) => {
    if (inSelection(item)) {
      // if (this.isAnd) {
      //   return 'selected__and';
      // }
      return 'selected__or';
    }
    return '';
  };

  const parseSelectionString = (selStr) => {
    if (selStr.includes(andConcat)) {
      return {
        logic: 'and',
        selection: _.split(selStr, andConcat),
      };
    }
    return {
      logic: 'or',
      selection: _.split(selStr, orConcat),
    };
  };

  return {
    isOpen,
    isMultiSelect,
    label,
    subline,
    classForLabel,
    classForItem,
    options,
    selection,
    select,
    toggleMultiSelect,
    resetSelection,
    inSelection,
    inOptions,
    toggleOpen,
    close,
    selectionString,
    parseSelectionString,
  };
}
