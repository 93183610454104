// keep this in sync with feature_flip.rb

const hasFeatureBitCompare = (currentUser, bitmaskPos) => {
  if (currentUser == null) { return false; }

  // this is indeed a bit comparison, but eslint doesn't like it
  // eslint-disable-next-line no-bitwise
  return (currentUser.featureBitmask & bitmaskPos) > 0;
};

const hasFeatureArrayCompare = (currentUser, key) => {
  if (currentUser == null) { return false; }
  return (currentUser.activeFlippers.includes(key));
};

const FeatureFlipper = {
  computed: {
    features() {
      if (this.currentUser == null) {
        // eslint-disable-next-line no-console
        console.error('WARNING: currentUser is null, all features will be disabled');
      }
      return {
        // keep decimal bit representation in sync with feature_flip.rb
        incidentsPro: hasFeatureBitCompare(this.currentUser, 2048),
        announcementEmail: hasFeatureBitCompare(this.currentUser, 512),
        stockPro: hasFeatureBitCompare(this.currentUser, 134217728),
        canChangeLayoutEngine: hasFeatureBitCompare(this.currentUser, 65536),
        auxJobAttachments: hasFeatureArrayCompare(this.currentUser, 'aux_job_attachments'),
        detectortesters: hasFeatureBitCompare(this.currentUser, 262144),
        integrations: hasFeatureBitCompare(this.currentUser, 4194304),
        calendric_intervals: hasFeatureBitCompare(this.currentUser, 524288),
        totalImport: hasFeatureBitCompare(this.currentUser, 2097152),
      };
    },
  },
};
export default FeatureFlipper;
