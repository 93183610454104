<template>
  <span v-if="showLink">
    <a :href="href_url" :title="href_url" target="_blank"><span :class="classes" class="link"><i
      class="fas fa-external-link"
      style="margin-right: .3rem"
    />{{ labelText }}</span></a>
  </span>
</template>

<script>
import { mapState } from 'pinia';
import { useCurrentUserStore } from 'stores/current_user';
import UserModel from 'apps/application/user_model';

export default {
  name: 'ExternalLinkButton',
  props: {
    href: {
      type: String,
      default: '',
    },
    defaultLabelText: {
      type: String,
      default: 'Link',
    },
    classes: {
      type: String,
      default: 'fx-label',
    },
  },
  computed: {
    ...mapState(useCurrentUserStore, ['currentUser']),
    showLink() {
      return (
        this.href != null
        && this.href.length
        && this.currentUser.pro
        && !UserModel.isPortal(this.currentUser)
      );
    },
    href_url() {
      if (this.href == null) { return ''; }
      return this.href.split('|')[0];
    },
    labelText() {
      if (this.href == null) { return this.defaultLabelText; }

      const customLabel = this.href.split('|')[1];
      if ((customLabel != null) && (customLabel.length)) {
        return customLabel.substring(0, 15);
      }
      try {
        return this.domainFromURL(this.href_url);
      } catch (error) {
        return this.defaultLabelText;
      }
    },
  },
  methods: {
    domainFromURL(url) {
      let domain = (new URL(url));
      domain = domain.hostname.replace('www.', '');

      return domain;
    },
  },
};
</script>
