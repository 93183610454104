<template>
  <div id="server-errors">
    <div v-if="modelValue.length" class="callout callout--danger" :style="styles()">
      <strong>{{ title }}</strong>
      <div v-for="(error, index) in modelValue" :key="index">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ServerErrors',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Daten konnten nicht gespeichert werden',
    },
    marginBottom: {
      type: String,
      default: '0',
    },
  },
  methods: {
    styles() {
      return `color: #ec5840; margin-bottom: ${this.marginBottom}`;
    },
  },
};
</script>
