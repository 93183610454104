<template>
  <div>
    <a v-if="link" :href="installationUrl" class="no-color">
      <div class="secondary">{{ installation.number }}</div>
      {{ installation.full_name }}
    </a>
    <template v-else>
      <div class="secondary">
        {{ installation.number }}
      </div>
      {{ installation.full_name }}
    </template>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    installation: {
      type: Object,
      required: true,
    },
    link: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    installationUrl() {
      return `/installations/${this.installation.id}`;
    },
  },
};
</script>
