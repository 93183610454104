<template>
    <div class="fx-modal-content-large">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <div v-if="rawEvent">
      <h2 class="fx">
        Event-Details
      </h2>
      <table class="material">
        <tr>
          <th style="width: 120px;">
            Event ID
          </th>
          <td>{{ rawEvent.id }}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>
            {{ rawEvent.type }}
            <div v-if="rawEvent.noop">
              <span class="fx-status warning">NOOP</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>Aggregate</th>
          <td>{{ rawEvent.aggregate_id }}</td>
        </tr>
        <tr>
          <th>Eventdate</th>
          <td>
            <strong>{{ formatDate(rawEvent.eventdate, 'DD.MM.YYYY') }}</strong>
            {{ formatDate(rawEvent.eventdate, 'HH:mm') }} Uhr
          </td>
        </tr>
        <tr v-if="rawEvent.metadata.api_key">
          <th>API Key</th>
          <td><a :href="`/backoffice/api_keys/${rawEvent.metadata.api_key}`">{{ rawEvent.metadata.api_key }}</a></td>
        </tr>
      </table>
      <div style="font-size: 12px">
        <strong>Data</strong><br>
        <PrettyJSON :json="rawEvent.data" />
      </div>
      <div style="font-size: 12px">
        <strong>Meta</strong><br>
        <PrettyJSON :json="rawEvent.metadata" />
      </div>
    </div>
  </div>
</template>

<script>
import PrettyJSON from 'components/pretty_json.vue';
import { DateFormatter } from 'mixins/formatters';

export default {
  name: 'RawEventModal',
  components: {
    PrettyJSON,
  },
  mixins: [DateFormatter],
  props: {
    rawEvent: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
};
</script>
