<template>
<div class="fx-nav-personal">
  <OnClickOutside style="display: inline-block;" @trigger="onClickOutside">
  <button
    id="topbar-button-personal-settings"
    v-tippy="{ placement: 'left', duration: 100, arrow: true }"
    class="fx-nav-personal"
    :class="{ 'fx-nav-personal-active': isActiveNav('personal') }"
    :title="$t('nav.main.personal-settings')"
    @click.stop.prevent="toggleDropdown('personal')"
  >
    <img
      :src="currentUser.avatarPath"
      width="28px"
      height="28px"
      style="vertical-align: middle;"
    >
  </button>

  <div v-if="show" class="fx-nav-personal-dropdown">
    <img :src="currentUser.avatarPath" style="float:left; padding: .5rem; width: 56px; height: 56px;">
    <div class="clearfix" style="margin-left: 40px; margin-right:30px; margin-top: 12px;">
      <div id="topbar-info-username" style="font-size: 14px; font-weight: bold; line-height:1;">
        {{ currentUser.fullname }}
      </div>
      <div
        id="topbar-info-email"
        style="line-height:1.1;font-size: 12px; white-space: nowrap;
      margin-top: 2px; margin-bottom: 10px;"
      >
        {{ currentUser.email }}
      </div>
    </div>

    <a v-if="withSwitchUser()" id="topbar-button-switch-organization" class="fx-nav-organization-switch" @click="openSwitchUserModal()">
      <div class="fx-nav-organization-switch">
        <div id="topbar-info-organization" class="fx-nav-organization">{{ currentUser.organization }}&nbsp;<sup
          v-if="currentUser.pro"
        >
          <span class="license-type-label license-type-pro">pro</span></sup></div>
        <div id="topbar-info-branch" class="fx-nav-branch">{{ currentUser.branch }}</div>
      </div>
    </a>

    <a
      v-if="currentUser.foxtag"
      id="topbar-link-backoffice"
      href="/accounts/to_backoffice/"
      class="fx-nav-dropdown-link"
      style="letter-spacing: 2px;"
    >
      <i class="fas fa-star-of-life fa-fw" /> {{ $t('nav.personal.backoffice') }}
    </a>
    <a id="topbar-link-personal-settings" href="/accounts/my" class="fx-nav-dropdown-link">
      <i class="fas fa-user-cog fa-fw" /> {{ $t('nav.main.personal-settings') }}
    </a>
    <a v-if="withOtpLogin()" id="topbar-button-app-login" class="fx-nav-dropdown-link" @click="openOTPModal()"><i
      class="far fa-barcode-read fa-fw"
    /> {{
      $t('nav.personal.app-login') }}</a>
    <hr style="margin: 0; border:0; border-bottom: 1px solid #eee;">
    <a id="topbar-link-logout" href="/accounts/sign_out" class="fx-nav-dropdown-link"><i
      class="fas fa-sign-out fa-fw"
    /> {{
      $t('nav.personal.sign-out') }}</a>
  </div>
</OnClickOutside>
</div>
</template>
<script>

import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import { includes } from 'lodash';
import { OnClickOutside } from '@vueuse/components';
import { useCurrentUserStore } from 'stores/current_user';
import OTPLoginModal from 'components/otp_login_modal.vue';
import SwitchUserModal from 'components/switch_user_modal.vue';
import { userSitemap } from 'initializers/sitemap';

export default {
  components: {
    OnClickOutside,
  },
  props: {
    activeNav: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const currentUser = useCurrentUserStore().currentUser;
    const userPaths = userSitemap(currentUser);

    const isActiveNav = (nav) => (nav === props.activeNav);

    const openOTPModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: OTPLoginModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openSwitchUserModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SwitchUserModal,
            attrs: {
              currentUser,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      currentUser,
      openOTPModal,
      openSwitchUserModal,
      isActiveNav,
      userPaths,
    };
  },
  data() {
    return {
      show: false,
      dropdowns: {
        personal: false,
      },
    };
  },
  methods: {
    onClickOutside() {
      if (this.show) {
        this.show = false;
      }
    },
    hideDropdowns(notThisOne) {
      const drops = this.dropdowns;

      Object.keys(drops).forEach((property) => {
        if (property !== notThisOne) {
          drops[property] = false;
        }
      });
    },
    toggleDropdown() {
      this.show = !this.show;
    },
    withOtpLogin() {
      return includes(this.currentUser.roles, 'admin') || includes(this.currentUser.roles, 'execute');
    },
    withSwitchUser() {
      return includes(this.currentUser.roles, 'admin') || includes(this.currentUser.roles, 'execute') || includes(this.currentUser.roles, 'manage');
    },
  },
};
</script>
