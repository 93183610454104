<template>
  <transition name="back-to-top-fade">
    <div
      v-show="visible"
      class="v--back-to-top"
      @click="backToTop"
    >
      <slot>
        <div class="default">
          <i class="fas fa-arrow-up" />
          <span>
            {{ text }}
          </span>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    text: {
      type: String,
      default: '',
    },
    visibleoffset: {
      type: [String, Number],
      default: 700,
    },
    // visibleoffsetbottom: {
    //    type: [String, Number],
    //    default: 0,
    //  },
    scrollFn: {
      type: Function,
      default(eventObject) {},
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    window.smoothscroll = () => {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll);
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)));
      }
    };
    window.addEventListener('scroll', this.catchScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.catchScroll);
  },
  methods: {
    /**
     * Catch window scroll event
     * @return {void}
     */
    catchScroll() {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
      // const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom)
      // this.visible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset
      this.visible = pastTopOffset;
      this.scrollFn(this);
    },
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop() {
      window.smoothscroll();
      this.$emit('scrolled');
    },
  },
};
</script>
<style></style>
