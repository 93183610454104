<template>
  <i :class="classes" />
</template>

<script>
import foxtagIcons from 'initializers/icons';

export default {
  name: 'FoxtagIcon',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    classes() {
      return foxtagIcons[this.id];
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>
