// make formatters available to composition API
export const formatDate = (dateString, formatString) => {
  // args:
  //  - dateString: Date in iso8601 format (type: String)
  //  - formatString: Format as defined in date_mapper (type: String)
  // Notes:
  //  - returns String [UNKNOWN FORMAT] if given formatString not defined
  //  - returns empty string '' if dateString is nil
  if (!dateString) return '';

  let date = new Date(); // current date
  if (dateString !== 'current') {
    date = new Date(dateString);
  }

  // extract date elements
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');

  // format_mapper
  const dateMapper = {
    'YYYY-MM-DD': `${year}-${month}-${day}`,
    'DD.MM.YYYY': `${day}.${month}.${year}`,
    'DD.MM.YYYY HH:mm': `${day}.${month}.${year} ${hour}:${minute}`,
    'DD.MM.YYYY HH:mm:ss': `${day}.${month}.${year} ${hour}:${minute}:${second}`,
    'HH:mm': `${hour}:${minute}`,
    'HH:mm:ss': `${hour}:${minute}:${second}`,
  };

  return dateMapper[formatString] || '[UNKNOWN FORMAT]';
};

// make DateFormatter available as a mixin (options api)
export const DateFormatter = {
  methods: {
    formatDate(dateString, formatString) {
      return formatDate(dateString, formatString);
    },
  },
};

export const TruncateFormatter = {
  methods: {
    truncate(value, limit = 50) {
      if (!value) return '';

      const strValue = value.toString();
      if (strValue.length > limit) {
        return `${strValue.substring(0, limit)}...`;
      }

      return strValue;
    },
  },
};
