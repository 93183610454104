<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <h2 class="fx">
      Komponente suchen
    </h2>
    <div style="display: flex; align-items: center; width: 100%;">
      <div style="flex-grow: 1;">
        <label>Seriennummer oder Foxtag-ID</label>
        <input
          v-model="queryString"
          type="text"
        >
      </div>
      <button
        class="fx-btn-primary float-right"
        style="flex-shrink: 0; margin-left: 0.5rem; margin-top: 0.5rem;"
        @click="searchThings()"
        >
        Suchen
      </button>
    </div>
    <br>

    <div v-if="searchState">
      <div v-if="localThings.length > 0">
        <!--  <label> Suchergebnis </label> Kann man auch weglassen -->
        <table class="material">
          <!--Table HEADER-->
          <tr class="material__title">
            <th style="width: 35%;">
              {{ $t('activerecord.attributes.thing.thing_type') }}
            </th>
            <th>
              Details
            </th>
            <th style="width: 25%;">
              Status
            </th>
          </tr>
          <tr v-for="thing in localThings" :key="thing.id" class="cursor-pointer" @click="navigateToThing(thing.id)">
            <td>
              <div class="fx_thing_type_with_icon">
                  {{ thing.thing_type.name }}
                <div class="secondary">
                  {{ thingMake(thing) }}
                </div>
              </div>
            </td>
            <td>
              <table class="fx-thing-details">
                <tr v-if="thing.active_tag_serial">
                  <th class="fx-thing-details">
                    {{ tagLabel(thing) }}
                  </th><td class="fx-thing-details t-thing-tag">
                    {{ humanTagSerial(thing) }}
                  </td>
                </tr>
                <tr v-if="thing.serial_number">
                  <th class="fx-thing-details">
                    S/N
                  </th><td class="fx-thing-details t-thing-serial">
                    {{ thing.serial_number }}
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <div>
                {{ $t(`activerecord.attributes.thing.statuses.${thing.status}`) }}
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="localThings.length < 1" style="text-align: center;">
          Es wurden keine Komponenten gefunden.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref } from 'vue';

import ThingPresenter from 'mixins/thing_presenter';

export default {
  name: 'SearchModal',
  components: {
  },
  mixins: [ThingPresenter],
  props: {
    passedQueryString: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      default: 'far fa-eye',
    },
  },
  emits: ['close'],
  setup(props) {
    const localThings = ref([]);
    const queryString = ref(props.passedQueryString);
    const searchState = ref(false);

    const searchThings = () => {
      axios.get('/things/search.json', {
        params: {
          query: queryString.value,
        },
      }).then((response) => {
        localThings.value = response.data.things;
        searchState.value = true;
      }).catch((err) => {
        Flash.error(err);
      });
    };

    const navigateToThing = (thingId) => {
      window.location.href = `/things/${thingId}`;
    };

    const tagLabel = (thing) => {
      if (thing.tag_label) {
        return thing.tag_label;
      }
      return 'Foxtag-ID';
    };

    return {
      localThings,
      queryString,
      searchState,
      searchThings,
      tagLabel,
      navigateToThing,
    };
  },
  mounted() {
    this.searchThings();
  },
};
</script>
