<template>
  <div class="fx-modal-content-small">
    <h2 class="fx">
      {{ title }}
    </h2>
    <div style="min-height: 25px;">
      {{ message }}

      <div v-if="requireInputConfirmation" style="margin-top: 1rem;">
        <label>Tippen Sie das Wort '{{ confirmationWord }}' als Bestätigung:</label>
        <input v-model="confirmationInput" type="text" :placeholder="`'${confirmationWord}' eintippen`">
      </div>
    </div>
    <div style="margin-top: 2rem;">
      <button
        id="confirm-modal-btn"
        :disabled="disabledSubmit"
        class="fx-btn-primary float-right"
        :class="additionalClasses"
        style="margin-left: 0.5rem;"
        @click.prevent="confirmed()"
      >
        {{ action }}
      </button>

      <button class="fx-btn-secondary float-right" @click.prevent="cancelled()">
        Abbrechen
      </button>

      <div class="clearfix" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
      default: 'Bestätigen',
    },
    message: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: 'Ok',
    },
    redButton: {
      type: Boolean,
      default: true,
    },
    requireInputConfirmation: {
      type: Boolean,
      default: false,
    },
    confirmationWord: {
      type: String,
      default: 'löschen',
    },
  },
  emits: ['close', 'confirmed'],
  data() {
    return {
      confirmationInput: '',
    };
  },
  computed: {
    disabledSubmit() {
      return (this.requireInputConfirmation && this.confirmationInput !== this.confirmationWord);
    },
    additionalClasses() {
      if (this.redButton) {
        return 'fx-btn-red';
      }
      return '';
    },
  },
  methods: {
    cancelled() {
      this.$emit('close');
    },
    confirmed() {
      if (!this.requireInputConfirmation || this.confirmationInput === this.confirmationWord) {
        this.$emit('confirmed');
        this.$emit('close');
      }
    },
  },
};
</script>

<style></style>
