<template>
  <nav id="topbar" :class="navClass">
    <a id="topbar-link-home" href="/" class="fx-nav-logo">
      <img :src="logoLarge" class="fx-nav-logo fx-hide-for-medium-and-large">
      <img :src="logoFoxOnly" class="fx-nav-logo fx-hide-for-xlarge-or-larger fx-hide-for-small">
    </a>

    <div v-if="hasWebRole" class="fx-nav-search fx-hide-for-small">
      <div class="fx-nav-search-inner">
        <i class="fas fa-search" />
        <input
          id="topbar-input-search"
          v-model="query"
          type="text"
          maxlength="100"
          name="search"
          :placeholder="$t('nav.main.search-placeholder')"
          class="fx-nav-search-input"
          autocomplete="one-time-code"
          @keydown="preventUpAndDownKeyBehavior"
          @keyup="handleSearchInput"
        >
      </div>

      <div v-if="searchActive" class="fx-nav-search-dropdown">
        <a
          v-for="(result, index) in searchResults"
          :key="index"
          :class="{ 'fx-nav-search-link': true, 'selected': index === highlightIndex }"
          :href="result.url"
        >
          <div class="fx-nav-search-model">{{ result.type }}</div>
          <div class="fx-nav-search-label"><strong v-if="result.number" style="margin-right: 7px;">{{ result.number
          }}</strong>{{ result.title }}</div>
        </a>
        <div v-if="searchResults.length == 0" style="padding: 15px;">
          Keine Ergebnisse für '{{ query }}' gefunden.
          <div style="font-size: 0.8rem; margin: 1rem 0;">
            <div>
              Suchen Sie beispielsweise nach einer <strong>Kunden-</strong>,
              <strong>Auftrags-</strong> oder <strong>Anlagennummer</strong>.
              <br>
              Probieren Sie außerdem <strong>Straße, Postleitzahl, Ort</strong> oder <strong>Wartungsanwendung</strong>.
            </div>
            <br>
            <div>
              <button class="fx-btn-secondary" @click="openSearchThingModal(query)">
                <i class="fas fa-search" />
                &nbsp;
                Komponente '{{ query }}' suchen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasWebRole" class="fx-nav-buttons fx-hide-for-medium-or-smaller">
      <a
        id="topbar-link-start"
        href="/start"
        class="fx-nav-link"
        :class="{ 'fx-nav-link-active': isActiveNav('start') }"
      >
        {{ $t('nav.main.start') }}
      </a>

      <a
        id="topbar-link-map"
        href="/map"
        class="fx-nav-link"
        :class="{ 'fx-nav-link-active': isActiveNav('map') }"
      >
        {{ $t('nav.main.map') }}
      </a>

      <a
        id="topbar-link-customers"
        href="/customers"
        class="fx-nav-link"
        :class="{ 'fx-nav-link-active': isActiveNav('customers') }"
      >
        {{ $t('nav.main.customers') }}
      </a>

      <a
        id="topbar-link-locations"
        href="/locations"
        class="fx-nav-link"
        :class="{ 'fx-nav-link-active': isActiveNav('locations') }"
      >
        {{ $t('nav.main.installations') }}
      </a>

      <a
        id="topbar-link-jobs"
        href="/jobs"
        class="fx-nav-link"
        :class="{ 'fx-nav-link-active': isActiveNav('jobs') }"
      >
        {{ $t('nav.main.jobs') }}
      </a>

      <a
        v-if="features.incidentsPro"
        id="topbar-link-incidents"
        href="/incidents"
        class="fx-nav-link"
        :class="{ 'fx-nav-link-active': isActiveNav('incidents') }"
      >
        {{ $t('nav.main.incidents') }}
      </a>

      <a
        v-if="userIsPro"
        id="topbar-link-analysis"
        href="/analysis"
        class="fx-nav-link"
        :class="{ 'fx-nav-link-active': isActiveNav('analysis') }"
      >
        {{ $t('nav.main.analysis') }}
      </a>
    </div>

    <div id="topbar-settings-area" class="fx-nav-settings-area">
      <div class="fx-nav-magic">
&nbsp;
      </div>

      <a class="fx-nav-link fx-hide-for-medium-or-smaller" @click.stop="openHelp()">
        <!--<button id="topbar-button-help" class="fx-nav-personal" :title="$t('nav.main.help')"
          v-tippy="{ placement: 'left', duration: 100, arrow: true }">-->
        <img :src="iconHelp" class="fx-nav-icon">
        <!-- </button>-->
      </a>

      <a id="topbar-link-notifications" href="/notifications" class="fx-nav-link fx-hide-for-medium-or-smaller">
        <!--<button class="fx-nav-personal" :title="$t('nav.main.notifications')"
          :class="{ 'fx-nav-personal-active': isActiveNav('notifications') }"
          v-tippy="{ placement: 'left', duration: 100, arrow: true }">-->
        <img :src="iconNotifications" class="fx-nav-icon">
        <i
          v-if="unreadNotifications"
          class="fas fa-circle"
          style="font-size: 10px; color: red; position: relative; right: 10px; top: -10px; width: 0px;"
        />
        <!--</button>-->
      </a>

      <div id="topbar-personal" class="fx-nav-personal">
        <div v-if="hasWebRole" class="fx-nav-personal fx-hide-for-large-or-larger">
          <button
            id="topbar-button-menu"
            :title="$t('nav.main.menu')"
            class="fx-nav-personal"
            style="font-size: 0.9rem;"
            @click.stop.prevent="toggleDropdown('main')"
          >
            <i class="far fa-bars" /> {{ $t('nav.main.menu') }}
          </button>

          <!-- tablet viewpoint -->
          <div v-if="dropdowns['main']" class="fx-nav-personal-dropdown" style="overflow: hidden;">
            <a id="topbar-link-alt-start" href="/start" class="fx-nav-dropdown-link">{{ $t('nav.main.start') }}</a>
            <a id="topbar-link-alt-map" href="/map" class="fx-nav-dropdown-link">{{ $t('nav.main.map') }}</a>
            <a id="topbar-link-alt-customers" href="/customers" class="fx-nav-dropdown-link">{{ $t('nav.main.customers')
            }}</a>
            <a id="topbar-link-alt-locations" href="/locations" class="fx-nav-dropdown-link">{{
              $t('nav.main.installations')
            }}</a>
            <a id="topbar-link-alt-jobs" href="/jobs" class="fx-nav-dropdown-link">{{ $t('nav.main.jobs') }}</a>
            <a
              v-if="features.incidentsPro"
              id="topbar-link-alt-incidents"
              href="/incidents"
              class="fx-nav-dropdown-link"
            >{{ $t('nav.main.incidents')
            }}</a>
            <a v-if="userIsPro" href="/analysis" class="fx-nav-dropdown-link">{{ $t('nav.main.analysis') }}</a>
            <hr style="margin: 0; border:0; border-bottom: 1px solid #eee;">
            <a id="topbar-link-alt-notifications" href="/notifications" class="fx-nav-dropdown-link">
              <i class="far fa-bell" />
              {{ $t('nav.main.notifications') }}
            </a>
            <a id="topbar-link-alt-admin" href="/organizations/settings" class="fx-nav-dropdown-link">
              <i class="far fa-cog" /> {{ $t('nav.main.administration') }}</a>
            <a id="topbar-button-alt-help" class="fx-nav-dropdown-link" @click.prevent="openHelp()">
              <i class="far fa-question-circle" /> {{ $t('nav.main.help') }}</a>
          </div>
        </div>

        <button
          v-if="userIsAdminOrManager"
          id="topbar-button-admin"
          v-tippy="{ placement: 'left', duration: 100, arrow: true }"
          class="fx-nav-personal fx-hide-for-medium-or-smaller"
          :class="{ 'fx-nav-personal-active': isActiveNav('admin') }"
          :title="$t('nav.main.administration')"
          @click.prevent.stop="toggleDropdown('admin')"
        >
          <img :src="iconCog" class="fx-nav-icon">
        </button>

        <div v-if="dropdowns['admin']" class="fx-nav-personal-dropdown" style="overflow: hidden;">
          <a v-if="userIsAdmin" id="dropdown-link-installation-types" href="/installation_types" class="fx-nav-dropdown-link">
            <i class="fas fa-drafting-compass fa-fw" />
            {{ $t('nav.admin.installation-types') }}
          </a>
          <a id="dropdown-link-articles" href="/articles" class="fx-nav-dropdown-link"><i class="far fa-boxes fa-fw" />
            {{ $t('nav.admin.articles')}}
          </a>
          <a id="dropdown-link-contacts" href="/contacts" class="fx-nav-dropdown-link"><i class="fas fa-address-card fa-fw" />
            {{ $t('nav.admin.contacts') }}
          </a>
          <a id="dropdown-link-features" href="/organizations/features" class="fx-nav-dropdown-link"><i class="fas fa-tags fa-fw" />
            {{ $t('nav.admin.labels') }}
          </a>
          <a v-if="userIsAdmin" id="dropdown-link-report-config" href="/organization_configs/reports" class="fx-nav-dropdown-link">
            <i class="far fa-file-image fa-fw" />
            {{ $t('nav.admin.reports') }}
          </a>
          <hr v-if="userIsAdmin" style="margin: 0; border:0; border-bottom: 1px solid #eee;">
          <a
            v-if="userPaths['administration/organization']"
            id="dropdown-link-admin-organization"
            :href="userPaths['administration/organization'].href"
            class="fx-nav-dropdown-link">
            <FoxtagIcon :id="userPaths['administration/organization'].icon" class="fa-fw" />
            {{ $t('nav.admin.organization') }}
          </a>
          <a
            v-if="userPaths['administration/users']"
            id="dropdown-link-admin-users"
            :href="userPaths['administration/users'].href"
            class="fx-nav-dropdown-link">
            <FoxtagIcon :id="userPaths['administration/users'].icon" class="fa-fw" />
            {{ $t('nav.admin.users') }}
          </a>
          <a
            v-if="userPaths['administration/licenses']"
            id="dropdown-link-admin-licenses"
            :href="userPaths['administration/licenses'].href"
            class="fx-nav-dropdown-link">
            <FoxtagIcon :id="userPaths['administration/licenses'].icon" class="fa-fw" />
            {{ $t('nav.admin.licenses') }}
          </a>
          <a
            v-if="userPaths['administration/integrations']"
            id="dropdown-link-admin-integration"
            :href="userPaths['administration/integrations'].href"
            class="fx-nav-dropdown-link">
            <FoxtagIcon :id="userPaths['administration/integrations'].icon" class="fa-fw" />
            {{ $t('nav.admin.integrations') }}
          </a>

          <hr style="margin: 0; border:0; border-bottom: 1px solid #eee;">
          <a id="dropdown-link-admin-imports" :href="userPaths['administration/import'].href" class="fx-nav-dropdown-link">
            <FoxtagIcon :id="userPaths['administration/import'].icon" class="fa-fw" />
            {{ $t('nav.admin.import') }}
          </a>

          <hr style="margin: 0; border:0; border-bottom: 1px solid #eee;">
          <a id="dropdown-link-settings" href="/organizations/settings" class="fx-nav-dropdown-link">
            <button class="fx-btn-skeleton" style="width: 100%;">{{ $t('nav.admin.all-settings') }}</button></a>
        </div>
      </div>

      <PersonalMenu :active-nav="activeNav" />
    </div>
  </nav>
</template>

<script>
import iconCog from 'images/cog.svg';
import iconHelp from 'images/help.svg';
import iconNotifications from 'images/notifications.svg';
import logoLarge from 'images/logo.svg';
import logoFoxOnly from 'images/logo-fox-only.svg';

import axios from 'axios';
import _ from 'lodash';
import { userSitemap } from 'initializers/sitemap';

import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import { useCurrentUserStore } from 'stores/current_user';

import FeatureFlipper from 'mixins/feature_flipper';

import FoxtagIcon from 'components/icon.vue';

import PersonalMenu from 'components/topbar/personal_menu.vue';
import OTPLoginModal from 'components/otp_login_modal.vue';
import HelpModal from 'components/help_modal.vue';
import SwitchUserModal from 'components/switch_user_modal.vue';
import SearchThingModal from '../../things/search_modal.vue';
import CommandModal from './command_modal.vue';

const keyCodes = {
  enter: 13,
  esc: 27,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  shift: 16,
};

const buttonCodes = {
  left: 0,
};

export default {
  components: {
    FoxtagIcon,
    PersonalMenu,
  },
  mixins: [FeatureFlipper],
  props: {
    activeNav: {
      type: String,
      required: true,
    },
    fixedPosition: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const currentUser = useCurrentUserStore().currentUser;
    const userPaths = userSitemap(currentUser);

    const openHelp = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: HelpModal,
            attrs: {
              initTopicId: null,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openOTPModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: OTPLoginModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openSwitchUserModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SwitchUserModal,
            attrs: {
              currentUser,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openCommandModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: CommandModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openSearchThingModal = (passedQueryString) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SearchThingModal,
            attrs: {
              passedQueryString,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      userPaths,
      openHelp,
      openOTPModal,
      openSwitchUserModal,
      openCommandModal,
      currentUser,
      openSearchThingModal,
    };
  },
  data() {
    return {
      query: '',
      logoLarge,
      logoFoxOnly,
      iconCog,
      iconHelp,
      iconNotifications,
      dropdowns: {
        admin: false,
        main: false,
        personal: false,
      },
      searchWaitingForResponse: true,
      searchActive: false,
      searchResults: [],
      highlightIndex: 0,
      lastShiftPressed: 0,
      unreadNotifications: false,
    };
  },
  computed: {
    userRoles() {
      return this.currentUser.roles;
    },
    profilePath() {
      return '/accounts/my';
    },
    avatarPath() {
      return this.currentUser.avatarPath;
    },
    userIsPro() {
      return this.currentUser.pro;
    },
    userIsAdmin() {
      return _.includes(this.userRoles, 'admin');
    },
    userIsAdminOrManager() {
      return _.includes(this.userRoles, 'admin') || _.includes(this.userRoles, 'manage');
    },
    userIsTechnician() {
      return _.includes(this.userRoles, 'execute');
    },
    hasWebRole() {
      return _.includes(this.userRoles, 'web');
    },
    navClass() {
      if (this.fixedPosition) {
        return 'fx-nav';
      }
      return 'fx-nav-not-fixed';
    },
  },
  methods: {
    selectSearchResult() {
      const res = this.searchResults[this.highlightIndex];
      if (res && res.url) {
        document.location.href = res.url;
      }
    },
    // Normally when pressing the up or down key in an input fields, the cursor
    // would jump to the beginning or end of text. Since we’re using the up and
    // down keys for search result navigation, this is not really helpful.
    preventUpAndDownKeyBehavior(event) {
      if (event.keyCode === keyCodes.up || event.keyCode === keyCodes.down) {
        event.preventDefault();
      }
    },
    handleSearchInput(event) {
      if (event.keyCode === keyCodes.left || event.keyCode === keyCodes.right) {
        // ?
      } else if (event.keyCode === keyCodes.up) {
        if (this.highlightIndex > 0) {
          this.highlightIndex -= 1;
        }
      } else if (event.keyCode === keyCodes.down) {
        if (this.highlightIndex < this.searchResults.length - 1) {
          this.highlightIndex += 1;
        }
      } else if (event.keyCode === keyCodes.enter) {
        this.selectSearchResult();
      } else if (event.keyCode === keyCodes.esc) {
        this.resetSearch();
      } else {
        this.debouncedSearch();
      }
    },
    // beware: do not use ES6 style "() => {}" here, it messes the this reference!
    // eslint-disable-next-line func-names
    debouncedSearch: _.debounce(function () {
      this.search();
    }, 250),
    search() {
      if (this.query.length > 1) {
        this.searchActive = true;

        axios.get(`/multisearch?query=${encodeURIComponent(this.query)}`)
          .then((response) => {
            this.searchResults = response.data.search_results;
          })
          .catch(() => {
            this.searchResults = [];
          });
      } else {
        this.searchActive = false;
      }
    },
    resetSearch() {
      this.highlightIndex = 0;
      this.searchActive = false;
      this.query = '';
    },
    documentClick(event) {
      if (event.button === buttonCodes.left) {
        this.hideDropdowns(null);
        this.resetSearch();
      }
    },
    hideDropdowns(notThisOne) {
      const drops = this.dropdowns;

      Object.keys(drops).forEach((property) => {
        if (property !== notThisOne) {
          drops[property] = false;
        }
      });
    },
    toggleDropdown(selected) {
      this.hideDropdowns(selected);
      this.dropdowns[selected] = !this.dropdowns[selected];
    },
    isActiveNav(nav) {
      return nav === this.activeNav;
    },
    checkActiveUser(event) {
      axios.get(`/accounts/status?user_id=${this.currentUser.id}`).catch((error) => {
        if (error.response.status === 410) {
          window.location.href = '/logged_out_info';
        }
      });
    },
    updateNotificationState() {
      this.unreadNotifications = !!localStorage.getItem('unreadNotifications');
    },
    queryNotificationState() {
      axios.get('/notifications/pending.json', {
      }).then((response) => {
        if (response.data.pending) {
          localStorage.setItem('unreadNotifications', true);
        } else {
          localStorage.removeItem('unreadNotifications');
        }
        this.updateNotificationState();
      });
    },
  },
  created() {
    document.addEventListener('click', this.documentClick);
    window.addEventListener('focus', this.checkActiveUser);
  },
  unmounted() {
    document.removeEventListener('click', this.documentClick);
    window.removeEventListener('focus', this.checkActiveUser);
  },
  mounted() {
    this.updateNotificationState();
    this.queryNotificationState();

    const that = this;
    if (that.currentUser.foxtag) {
      window.addEventListener('keyup', (event) => {
        if (event.key === 'Shift') {
          const now = window.performance.now();
          if (now - that.lastShiftPressed < 300) {
            that.openCommandModal();
          }
          that.lastShiftPressed = now;
        }
      });
    }
  },
};
</script>
