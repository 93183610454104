<template>
  <div id="topbar-no-distraction">
    <div class="no-distraction__topbar">
      <a v-if="currentUser !== null" href="/users/start">
        <img
          src="/fx-logo-topbar-1.svg"
          alt="Foxtag"
          title="Foxtag"
          style="height: 25px; margin: 15px 20px;"
          >
      </a>
      <a v-else href="https://www.foxtag.de">
        <img
          src="/fx_topbar_logo@2x_v1.png"
          alt="Foxtag"
          title="Foxtag"
          style="width: 213px; height: 65px; margin-left: 30px; margin-bottom: 10px;"
        >
      </a>
    </div>
  </div>
</template>
<script>
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'NoDistractionTopbar',
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
};
</script>
