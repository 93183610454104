const foxtagIcons = {
  settings: 'fas fa-cog',
  incidents: 'fas fa-exclamation-triangle',
  installation_type: 'fas fa-drafting-compass',
  installation: 'fas fa-sitemap',
  articles: 'far fa-boxes',
  contact: 'fas fa-address-card',
  thing: 'fas fa-cube',
  thing_type: 'far fa-cube',
  labels: 'fas fa-tags',
  label: 'fas fa-tag',
  job_settings: 'far fa-clipboard-check',
  report_logo_settings: 'far fa-file-image',
  installation_template: 'far fa-clone',
  import: 'far fa-file-import',
  export: 'far fa-file-export',
  organization_settings: 'fas fa-university',
  email: 'far fa-envelope',
  calendar: 'far fa-calendar-alt',
  app_settings: 'fas fa-tablet-alt',
  team: 'fas fa-user-friends',
  license: 'fas fa-badge-check',
  updates: 'far fa-gift',
  link: 'fas fa-external-link',

  integration: 'fas fa-plus-hexagon',
  api: 'far fa-brackets-curly',
  keyaccount: 'far fa-university',

  welcome: 'fas fa-stars',
  user_profile: 'fas fa-id-card-alt',
  security: 'fas fa-lock',
  notifications: 'fas fa-bell',
  devices: 'fas fa-tablet-alt',
  job: 'far fa-calendar-check',
  sign_out: 'fas fa-sign-out',
  logs: 'fas fa-scroll',

  help: 'fas fa-question-circle',
  contact_us: 'fas fa-phone-rotary',

  customer: 'fas fa-briefcase',
  location: 'fas fa-building',
  user: 'fas fa-user',
  job_status: 'fas fa-flag',
  incident_status: 'fas fa-flag',
  check_status: 'fas fa-circle',
  replacement_status: 'far fa-sync-alt',
  incident_severity: 'fas fa-square',
  timerange: 'far fa-calendar-alt',

  inventory: 'far fa-boxes',
  custom_data: 'far fa-th-list',
  checklist: 'far fa-list',
  interval: 'far fa-sync-alt',
  incident_type: 'far fa-exclamation-triangle',
};
export default foxtagIcons;
