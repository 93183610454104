<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Kontakt konnte nicht gespeichert werden" margin-bottom="1rem" />

    <form>
      <div v-if="!onlyCreate" class="fx-form-row">
        <div class="fx-form-content">
          <input
            id="select-or-create-select"
            v-model="selectOrCreate"
            type="radio"
            value="select"
          ><label
            class="inline"
            for="select-or-create-select"
          >Aus Kontakten wählen</label>
          <input
            id="select-or-create-create"
            v-model="selectOrCreate"
            type="radio"
            value="create"
          ><label
            class="inline"
            for="select-or-create-create"
          >Neuen Kontakt anlegen</label>
        </div>
      </div>

      <template v-if="isSelect">
        <!-- min height to prevent modal from wobbling around if contact select changes height-->
        <div class="fx-form-row" style="min-height: 100px;">
          <div class="fx-form-label">
            <label class="inline">Kontakt</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="selectedContactId"
              icon-id="contact"
              i18n-base-key="contacts"
              :default-label="$t('comp.select.no-selection')"
              load-options-url="/contacts/for_select"
              @changed="onContactSelected"
            />
            <div class="form-field-hint">
              Kontakt aus der Liste aller Kontakte wählen.
            </div>
          </div>
        </div>
      </template>

      <template v-if="isCreate">
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="contact_name">Name</label>
          </div>
          <div class="fx-form-content">
            <input
              id="contact_name"
              v-model="localContact.name"
              name="contact_name"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="contact_company">Firma</label>
          </div>
          <div class="fx-form-content">
            <input
              id="contact_company"
              v-model="localContact.company"
              name="contact_company"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label :class="isEmailMandatory ? 'inline mandatory' : 'inline'">E-Mail</label>
          </div>
          <div class="fx-form-content">
            <input
              id="contact_email"
              v-model="localContact.email"
              name="contact_email"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline">Telefon</label>
          </div>
          <div class="fx-form-content">
            <input
              id="contact_phone"
              v-model="localContact.phone"
              name="contact_phone"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="contact_mobile">Mobil</label>
          </div>
          <div class="fx-form-content">
            <input
              id="contact_mobile"
              v-model="localContact.mobile"
              name="contact_mobile"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="contact_fax">Fax</label>
          </div>
          <div class="fx-form-content">
            <input
              id="contact_fax"
              v-model="localContact.fax"
              name="contact_fax"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="contact_note">Notiz</label>
          </div>
          <div class="fx-form-content">
            <textarea
              id="contact_mobile"
              v-model="localContact.note"
              rows="3"
              name="contact_note"
            />
          </div>
        </div>
      </template>
    </form>
    <hr>
    <input
      type="submit"
      value="Kontakt speichern"
      class="fx-btn-primary float-right"
      style="margin-bottom: 0.5rem;"
      :disabled="isSelect && selectedContactId == null"
      @click.prevent="submit"
    >
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import DBBasedSelect from 'components/select/db_based_select.vue';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'AddCreateContactModal',
  components: {
    ServerErrors,
    DBBasedSelect,
  },
  props: {
    installationId: {
      type: String,
      default: null,
    },
    customerId: {
      type: String,
      default: null,
    },
    onlyCreate: {
      type: Boolean,
      default: false,
    },
    relationType: {
      type: String,
      required: true,
    },
    contactIdList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['added', 'close'],
  data() {
    return {
      selectOrCreate: 'select',
      selectedContactId: null,
      localContact: {
        id: null,
        name: '',
        phone: '',
        email: '',
        mobile: '',
        note: '',
        fax: '',
      },
      contactList: [],
      serverErrors: [],
    };
  },
  computed: {
    title() {
      if (this.relationType === 'contact') {
        return 'Kontakt hinzufügen';
      }
      return 'E-Mail-Empfänger hinzufügen';
    },
    isSelect() {
      return (this.selectOrCreate === 'select');
    },
    isCreate() {
      return (this.selectOrCreate === 'create');
    },
    isEmailMandatory() {
      return (this.relationType === 'report_recipient');
    },
  },
  mounted() {
    if (this.onlyCreate) {
      this.selectOrCreate = 'create';
    }
  },
  methods: {
    onContactSelected(contactId) {
      this.selectedContactId = contactId;
    },
    submit() {
      if (this.isSelect) {
        this.addContactViaAPI();
      } else {
        this.createViaAPI();
      }
    },
    addContactViaAPI() {
      this.serverErrors = [];
      const that = this;

      // check if contact already exists, require list of contactsIds already existing
      if (this.contactIdList.includes(this.selectedContactId)) {
        Flash.info('Kontakt existiert bereits');
        this.$emit('close');
        return;
      }

      axios.post(`/contacts/${this.selectedContactId}/add`, {
        // only one is set, axios does not send null params
        installation_id: this.installationId,
        customer_id: this.customerId,
        relation_type: this.relationType,
      }).then((response) => {
        this.$emit('close');
        if (this.relationType === 'contact') {
          Flash.info('Kontakt wurde hinzugefügt');
        } else {
          Flash.info('E-Mail-Empfänger wurde hinzugefügt');
        }
        that.$emit('added', { ...response.data.contact });
      }).catch((error) => {
        that.serverErrors = error.response.data.contacts;
      });
    },

    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/contacts.json', {
        contact: {
          name: this.localContact.name,
          company: this.localContact.company,
          phone: this.localContact.phone,
          mobile: this.localContact.mobile,
          fax: this.localContact.fax,
          email: this.localContact.email,
          note: this.localContact.note,
        },
        relation_type: this.relationType,
      }).then((response) => {
        if (this.onlyCreate) {
          // e.g. the global list of contacts does not assign a new contact to anything
          this.$emit('close');
          Flash.info('Kontakt wurde hinzugefügt');
          that.$emit('added', { ...response.data.contact });
        } else {
          // typically contacts are added on the fly
          that.selectedContactId = response.data.contact.id;
          that.addContactViaAPI();
        }
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.contacts;
        } else {
          Flash.error('Kontakt konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>

<style></style>
