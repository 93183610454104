import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import JobQuickViewModal from 'apps/jobs/quick_view_modal.vue';
import IncidentQuickViewModal from 'apps/incidents/quick_view_modal.vue';

export const jobQuickView = (jobId) => new Promise((resolve) => {
  const modalInstance = useModal({
    component: VueFinalModal,
    slots: {
      default: useModalSlot({
        component: JobQuickViewModal,
        attrs: {
          jobId,
          onClose() {
            modalInstance.close();
          },
          onChanged(changedJob) {
            resolve(changedJob);
          },
        },
      }),
    },
  });

  modalInstance.open();
});

export const incidentQuickView = (incidentId) => new Promise((resolve) => {
  const modalInstance = useModal({
    component: VueFinalModal,
    slots: {
      default: useModalSlot({
        component: IncidentQuickViewModal,
        attrs: {
          incidentId,
          onClose() {
            modalInstance.close();
          },
          onChanged(changedIncident) {
            resolve(changedIncident);
          },
        },
      }),
    },
  });
  modalInstance.open();
});
