<template>
  <div>
    <slot name="topbar" />
    <slot name="content" />
    <ModalsContainer />
    <BackToTop />
  </div>
</template>
<script>
import { ModalsContainer } from 'vue-final-modal';
import { useCurrentUserStore } from 'stores/current_user';
import Flash from 'flash/index';
import BackToTop from 'components/back_to_top.vue';

export default {
  components: {
    ModalsContainer,
    BackToTop,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => { },
    },
    // rails flash messages
    flash: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useCurrentUserStore();
    if (props.currentUser && props.currentUser.id) { // some pages do not have a logged in user
      store.change(props.currentUser);
    } else {
      store.reset();
    }
  },
  created() {
    this.$root.$i18n.locale = document.documentElement.lang;
  },
  mounted() {
    setTimeout(() => {
      this.showRailsFlashMessages();
    }, 300);
  },
  methods: {
    showRailsFlashMessages() {
      if (this.flash == null) { return; }

      for (let i = 0; i < this.flash.length; i += 1) {
        if (this.flash[i][0] === 'alert') {
          Flash.error(this.flash[i][1]);
        } else {
          Flash.info(this.flash[i][1]);
        }
      }
    },
  },
};
</script>
