<template>
  <div>
    <i class="fas" :class="nextColorAndSymbol" />
    <span
      v-tippy="{ placement: 'top', duration: 100, delay: [500,0], arrow: true }"
      :class="classes"
      style="margin-left: .2rem;"
      :title="title"
    >{{ nextDateLabel }}</span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    isReplacement: {
      type: Boolean,
      required: false,
    },
    nextDate: {
      type: String,
      default: null,
    },
    nextStatus: {
      type: String,
      default: 'unknown',
    },
    withUnknownHint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      if (this.nextStatus === 'none' || this.nextStatus === 'unknown') {
        return 'secondary';
      }
      return '';
    },
    nextDateLabel() {
      if (this.nextStatus === 'none' || this.nextStatus === 'unknown') {
        return 'Fälligkeit unbekannt';
      }
      return this.nextDateFormatted;
    },
    nextDateFormatted() {
      if (this.nextDate) {
        return moment(this.nextDate).format('DD.MM.YYYY');
      }
      return '';
    },
    nextColorAndSymbol() {
      if (this.isReplacement) {
        return `fa-sync-alt color-${this.nextStatus}`;
      }
      return `fa-circle color-${this.nextStatus}`;
    },
    title() {
      if (this.nextStatus === 'none' || this.nextStatus === 'unknown') {
        return this.missingInfo;
      }
      if (this.isReplacement) {
        return `Nächster Austausch bis ${this.nextDateFormatted} fällig`;
      }
      return `Nächste Prüfung bis ${this.nextDateFormatted} fällig`;
    },
    missingInfo() {
      if (!this.withUnknownHint) {
        return 'Fälligkeit unbekannt';
      }

      if (this.isReplacement) {
        return 'Unbekannt weil Baujahr oder Inbetriebnahmedatum zur Berechnung fehlen';
      }
      return 'Unbekannt weil letzte Prüfung oder Inbetriebnahmedatum zur Berechnung fehlen';
    },
  },
};
</script>
