<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <h2 class="fx">
      App-Login per Scan
    </h2>
    <hr class="mt-0">

    <div style="height: 300px;">
      <div style="float: left;">
        <VueQrcode v-if="otpCode != null" :value="otpCode" :options="{ margin: 0, width: 280 }" />
      </div>

      <p style="margin-left: 300px;">
        Scannen Sie diesen QR-Code mit der Foxtag Smartphone-App, um sich ohne Eingabe von
        <span style="white-space: nowrap">E-Mail</span> und Passwort anzumelden.
        <help-article article-id="login_via_scan">
          Mehr erfahren
        </help-article>
      </p>

      <p style="margin-left: 300px;">
        Dieser Code ist für ein einzelnes Login innerhalb von 10 Minuten gültig.
        <br>
        <a class="fx-btn-secondary" style="margin-top: .5rem;" @click="loadOTP()">Neuen Code erzeugen</a>
      </p>

      <div
        class="secondary"
        style="margin-left: 300px; margin-bottom: 2px; font-size: .8rem; cursor: pointer; bborder-bottom: 1px solid #aaa"
        @click="showBaseUrl = !showBaseUrl"
      >
        Erweiterte Einstellungen
        <i v-if="showBaseUrl" class="fas fa-chevron-up" />
        <i v-if="!showBaseUrl" class="fas fa-chevron-down" />
      </div>

      <div v-if="showBaseUrl" style="margin-left: 300px;">
        <input v-model="baseUrl" type="text" placeholder="Custom base URL">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash';

import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  name: 'OTPLoginModal',
  components: {
    VueQrcode,
  },
  emits: ['close'],
  data() {
    return {
      otpCode: null,
      baseUrl: null,
      showBaseUrl: false,
    };
  },
  mounted() {
    this.loadOTP();
  },
  methods: {
    loadOTP() {
      const that = this;
      axios.get(`/users/start_otp?base_url=${this.baseUrl}`).then((response) => {
        that.otpCode = response.data.otp;
      }).catch(() => {
        Flash.error('QR-Code konnte nicht geladen werden');
      });
    },

  },
};
</script>
