<template>
  <div id="server-errors">
    <div v-if="Object.keys(modelValue).length" class="callout callout--danger" :style="styles()">
      <strong>Daten konnten nicht gespeichert werden</strong>
      <div v-for="(msg, index) in modelValue['messages']" :key="index">
        {{ msg }}
      </div>
    </div>
  </div>
</template>
<script>
// --- this is meant to be used for errors coming out of commands
// --- it does only list :base errors and should be used with the FieldError component

export default {
  name: 'ServerErrorsCommand',
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'Daten konnten nicht gespeichert werden',
    },
    marginBottom: {
      type: String,
      default: '0',
    },
  },
  methods: {
    styles() {
      return `color: #ec5840; margin-bottom: ${this.marginBottom}`;
    },
  },
};
</script>
