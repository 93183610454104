<template>
  <a :id="computedId(articleId)" :class="classes()" @click="openHelp(articleId)">
    <slot />
  </a>
</template>

<script>
import HelpModal from 'components/help_modal.vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

export default {
  name: 'HelpArticle',
  props: {
    articleId: {
      type: String,
      required: true,
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const openHelp = (topicId) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: HelpModal,
            attrs: {
              initTopicId: topicId,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });

      modalInstance.open();
    };

    const computedId = ((articleId) => `help-article-${articleId}`);

    return {
      computedId,
      openHelp,
    };
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    classes() {
      if (this.noStyle) {
        return '';
      }
      return 'fx-help-article';
    },
  },
};
</script>
