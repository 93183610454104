<template>
  <div class="fx-modal-content-medium" style="max-width: 700px;">
    <h2 class="fx">
      Neue Störung erfassen
    </h2>
    <ServerErrorsCommand
      v-model="commandServerErrors"
      title="Störung konnte nicht gespeichert werden"
      margin-bottom="1rem"
    />
    <div v-if="!resultingIncident" class="fx_form_boundary" style="margin-top: 1.5rem;">
      <form @submit.prevent="submit">
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory">Anlage</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="installationId"
              :readonly="false"
              icon-id="installation"
              :default-label="$t('comp.select.no-selection')"
              i18n-base-key="installations"
              load-options-url="/select/installations"
              @changed="(value) => { installationId = value; loadIncidentTypeOptions(value)}"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline">Komponente</label>
          </div>
          <div class="fx-form-content">
            <template v-if="installationId">
              <DBBasedSelect
                v-model="thingId"
                icon-id="thing"
                :default-label="$t('comp.select.no-selection')"
                i18n-base-key="things"
                :load-options-url="`/select/things?installation_id=${installationId}`"
                @changed="(value) => { thingId = value; }"
              />
            </template>
            <span v-else class="secondary small">Bitte wählen Sie zuerst eine Anlage</span>
          </div>
        </div>
        <hr>
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="incident_incident_type">Störungstyp</label>
          </div>
          <div class="fx-form-content">
            <template v-if="installationId">
              <select
                v-model="incidentTypeId"
                :disabled="!enableIncidentTypeSelect"
                @change="updateIncidentTypeDetails()"
              >
                <option v-for="opt in incidentTypeOptions" :key="opt.value" :value="opt.value">
                  {{ opt.text }}
                </option>
              </select>
            </template>
            <span v-else class="secondary small">Bitte wählen Sie zuerst eine Anlage</span>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="incident_status">Status</label>
          </div>
          <div class="fx-form-content">
            <input
              id="incident_status_reported"
              v-model="status"
              type="radio"
              value="reported"
            >
            <label for="incident_status_reported">{{ $t('activerecord.attributes.incident_status.statuses.reported')
            }}</label>
            <input
              id="incident_status_opened"
              v-model="status"
              type="radio"
              value="opened"
            >
            <label for="incident_status_opened">{{ $t('activerecord.attributes.incident_status.statuses.opened')
            }}</label>
            <input
              id="incident_status_closed"
              v-model="status"
              type="radio"
              value="closed"
            >
            <label for="incident_status_closed">{{ $t('activerecord.attributes.incident_status.statuses.closed')
            }}</label>
          </div>
        </div>

        <div v-if="status == 'closed'" class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="incident_status">Lösung</label>
          </div>

          <template v-if="true">
            <div class="fx-form-content">
              <input
                id="incident_resolution_resolved"
                v-model="resolution"
                type="radio"
                value="resolved"
              >
              <label for="incident_resolution_resolved">{{
                $t('activerecord.attributes.incident_status.resolutions.resolved')
              }}</label>

              <input
                id="incident_resolution_wontfix"
                v-model="resolution"
                type="radio"
                value="wontfix"
              >
              <label for="incident_resolution_wontfix">{{
                $t('activerecord.attributes.incident_status.resolutions.wontfix')
              }}</label>
            </div>
          </template>
          <template v-else>
            <span style="font-size: 0.9rem;">{{
              $t('activerecord.attributes.incident_status.resolutions.unresolved') }}</span>
          </template>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="incident_statusdate">
              Datum<span style="color: red"> *</span> / Uhrzeit
            </label>
          </div>
          <div class="fx-form-content">
            <DateAndTimeInput v-model="statusdate" @changed="onChangeStatusdate" />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory">Schweregrad</label>
          </div>
          <div class="fx-form-content">
            <input
              id="incident_severity_low"
              v-model="severity"
              type="radio"
              value="low"
            >
            <label for="incident_severity_low">
              {{ $t('activerecord.attributes.incident.severities.low') }}</label>
            <input
              id="incident_severity_normal"
              v-model="severity"
              type="radio"
              value="normal"
            >
            <label for="incident_severity_normal">
              {{ $t('activerecord.attributes.incident.severities.normal') }}</label>
            <input
              id="incident_severity_high"
              v-model="severity"
              type="radio"
              value="high"
            >
            <label for="incident_severity_high">
              {{ $t('activerecord.attributes.incident.severities.high') }}</label>
            <input
              id="incident_severity_notice"
              v-model="severity"
              type="radio"
              value="notice"
            >
            <label for="incident_severity_notice">
              {{ $t('activerecord.attributes.incident.severities.notice') }}</label>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="incident_reported_by">Gemeldet von</label>
          </div>
          <div class="fx-form-content">
            <input
              id="incident_reported_by"
              v-model="reportedBy"
              name="reportedBy"
              type="text"
            >
            <div class="form-field-hint">
              Name des Kunden, der die Störung gemeldet hat.
              Wird auf dem Bericht abgedruckt.
            </div>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="incident_note">Beschreibung</label>
          </div>
          <div class="fx-form-content">
            <textarea
              id="incident_note"
              v-model="description"
              name="description"
              rows="8"
              maxlength="2500"
            />
          </div>
        </div>

        <div class="fx-form-row" style="margin-bottom: 0">
          <div class="fx-form-content">
            <div style="margin-bottom: 1rem; text-align: right;">
              <span style="margin-right: 1rem;"><a @click="$emit('close')">abbrechen</a></span>
              <button type="submit" class="fx-btn-primary" :disabled="!installationId">
                Störung speichern
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <empty-state v-if="resultingIncident" :success-icon="true" :title="`Störung #${resultingIncident.number} gespeichert`">
      <div class="fx-btn-group">
        <a class="fx-btn-ico-secondary" @click="$emit('close')"><i class="fas fa-times" /> Fenster schließen</a>
        <a class="fx-btn-primary" :href="`/incidents/${resultingIncident.id}`">Störung ansehen</a>
      </div>
    </empty-state>
  </div>
</template>

<script>
import axios from 'axios';

import Flash from 'flash/index';
import ServerErrorsCommand from 'components/partials/server_errors_command.vue';
import DateAndTimeInput from 'components/date_and_time_input.vue';
import EmptyState from 'components/empty_state.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';

export default {
  name: 'IncidentNew',
  components: {
    ServerErrorsCommand,
    DBBasedSelect,
    DateAndTimeInput,
    EmptyState,
  },
  props: {
    initInstallationId: {
      type: String,
      default: null,
    },
    initThingId: {
      type: String,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      installationId: null,
      thingId: null,
      incidentTypeId: null,
      thingOptions: [],
      incidentTypeOptions: [],
      severity: 'normal',
      resolution: 'unresolved',
      note: '',
      description: '',
      reportedBy: null,
      statusdate: null,
      status: 'reported',
      inboxItem: null,
      commandServerErrors: {},
      resultingIncident: null,
    };
  },
  computed: {
    enableIncidentTypeSelect() {
      return this.installationId !== null;
    },
    enableThingSelect() {
      return this.installationId !== null;
    },
  },
  watch: {
    status: {
      handler(newVal) {
        if (newVal === 'closed') {
          this.resolution = 'resolved';
        } else {
          this.resolution = 'unresolved';
        }
      },
    },
  },
  mounted() {
    this.initNewIncident();
  },
  methods: {
    initNewIncident() {
      this.resultingIncident = null;
      this.installationId = null;
      this.description = '';
      this.incidentTypeId = null;
      this.severity = 'normal';
      this.resolution = 'unresolved';
      this.status = 'reported';
      this.reportedBy = null;

      if (this.initInstallationId && this.initInstallationId.length) {
        this.installationId = this.initInstallationId;
      }
      if (this.initThingId && this.initThingId.length) {
        this.thingId = this.initThingId;
      }

      this.loadIncidentTypeOptions(this.installationId);
    },
    submit() {
      this.createIncident();
    },
    createIncident() {
      const that = this;
      that.serverErrors = [];
      axios.post('/incidents', {
        installation_id: this.installationId,
        incident_type_id: this.incidentTypeId,
        thing_id: this.thingId,
        severity: this.severity,
        description: this.description,
        link: this.link,
        status: this.status,
        resolution: this.resolution,
        statusdate: this.statusdate,
        reported_by: this.reportedBy,
      }).then((response) => {
        this.$emit('created');
        that.resultingIncident = response.data.incident;
      }).catch((error) => {
        if (error.response.status === 400) {
          that.commandServerErrors = error.response.data;
        } else {
          Flash.error('Störung konnte nicht gespeichert werden');
        }
      });
    },
    updateIncidentTypeDetails() {
      if (this.incidentTypeId == null) {
        return;
      }

      axios.get(`/incident_types/${this.incidentTypeId}.json`)
        .then((response) => {
          this.description = response.data.incident_type.default_description;
          this.severity = response.data.incident_type.default_severity;
        }).catch();
    },
    loadIncidentTypeOptions(installationId) {
      if (!installationId) {
        this.incidentTypeOptions = [];
        // this.enableIncidentTypeSelect = false;
        this.thingOptions = [];
        this.enableThingSelect = false;
        return;
      }
      // this.enableThingSelect = true;
      axios.get(`/installations/${installationId}/incident_type_options`)
        .then((response) => {
          this.incidentTypeOptions = response.data;
          this.incidentTypeId = this.incidentTypeOptions[0].value;
          // this.enableIncidentTypeSelect = true;
          this.updateIncidentTypeDetails();
        }).catch();
    },
    onChangeStatusdate(value) {
      this.statusdate = value;
    },
  },
};
</script>
